import React from "react";
import stockData from "./KnowledgeData";
import ImgClose from "../../../assets/icons/close.png";
import ImgShowKnowledge from "../../../assets/icons/btn-showKnowledge.png";
// import AudioNiSound from "../../../assets/sounds/Ni Sound - Cute Interface -  Double Bubble Pop Notification.aac";
// import AudioLevelUp from '../../../assets/sounds/Gamemaster Audio - Magic Spells - Delight Ding, Bubbly, Level Up .aac';
// import AudioLevelUpFadeIn from '../../../assets/sounds/gamemaster-audio-magic-spells-FadeIn.m4a';
import "./KnowledgeModal.scss";
/**
 * Class KnowledgeModal
 */
class KnowledgeModal extends React.Component {
	/**
	 * Constructor
	 * @param {object} props
	 * @return {void}
	 */
	// constructor(props) {
	// 	super(props);
	// 	this.domSfxModal = null;
	// }

	/**
	 * Lifecycle
	 * Runs after the component output has been rendered to the DOM
	 * @return {void}
	 */
	componentDidMount() {
		// if (this.props.isShow) {
			// this.domSfxModal.muted = this.props.dataRound.isMute;
			// this.domSfxModal.currentTime = 0;
			// this.domSfxModal.play();
		// }
	}

	/**
	 * Lifecycle
	 * Run after the component update state or props
	 * @param {object} prevProps Previous props
	 * @param {object} prevState Previous state
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {
		// const { isShow, dataRound } = this.props;
		// if (isShow !== prevProps.isShow) {
		// 	if (isShow) {
		// 		this.domSfxModal.muted = dataRound.isMute;
		// 		this.domSfxModal.currentTime = 0;
		// 		this.domSfxModal.play();
		// 	} else {
		// 		this.domSfxModal.muted = true;
		// 		this.domSfxModal.currentTime = 0;
		// 		this.domSfxModal.pause();
		// 	}
		// }
	}

	/**
	 * Lifecycle
	 * Render jsx to browser
	 * @return {jsx}
	 */
	render() {
		const {
			isShow,
			groupRoundName,
			isShowTrivia,
			onClickTrivia,
			onClose,
			// isLevelUp,
		} = this.props;
		return (
			<React.Fragment>
				<div
					className={`modal-knowledge-child ${isShow ? "" : "app-hidden-modal"
						}`}
				>
					<div className="board">
						{isShowTrivia && (
							<button className="btn-showKnowledge" onClick={onClickTrivia}>
								<img src={ImgShowKnowledge} alt="" />
							</button>
						)}
						<span
							className="title"
							style={{ paddingLeft: isShowTrivia ? "82px" : "40%" }}
						>
							{stockData[groupRoundName].round}
						</span>
						<button className="btn-hide" onClick={onClose}>
							<img src={ImgClose} alt="" />
						</button>
						<div className="bg-modal">
							<div className="content-img" 
							style={{ paddingLeft: isShowTrivia ? "0px" : "50px" }}>
								<img src={stockData[groupRoundName].srcImg} alt=""></img>
							</div>
							<div className="content-1">
								<div className="content-bg-1">
									<span>{stockData[groupRoundName].title}</span>
								</div>
							</div>
							<div className="content-bg"></div>
							<div className="content-2" id="style-scroll-knowledge">
								<div className="content-text">
									<span>{stockData[groupRoundName].content}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default KnowledgeModal;
