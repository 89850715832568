import React from 'react';
import ImgClose from '../../../assets/icons/close.png';
import './KnowledgeListModal.scss';
// import AudioNiSound from '../../../assets/sounds/Ni Sound - Cute Interface -  Double Bubble Pop Notification.aac';
/**
 * Class KnowledgeListModal
 */
class KnowledgeListModal extends React.Component {
	// /**
	//  * Constructor
	//  * @param {object} props
	//  * @return {void}
	//  */
    //  constructor(props) {
	// 	super(props);
	// 	this.domAudio = null;
	// }

	// /**
	//  * Lifecycle
	//  * Runs after the component output has been rendered to the DOM
	//  * @return {void}
	//  */
	// componentDidMount() {
	// 	if (this.props.isShow) {
	// 		this.domAudio.muted = this.props.dataRound.isMute;
	// 		// this.domAudio.currentTime = 0;
	// 	}
	// }

	// /**
	//  * Lifecycle
	//  * Run after the component update state or props
	//  * @param {object} prevProps Previous props
	//  * @param {object} prevState Previous state
	//  * @return {void}
	//  */
	// componentDidUpdate(prevProps, prevState) {
	// 	const { isShow, dataRound } = this.props;
	// 	if (isShow !== prevProps.isShow) {
	// 		if (isShow) {
	// 			this.domAudio.muted = dataRound.isMute;
	// 			// this.domAudio.currentTime = 0;
	// 			this.domAudio.play();
	// 		} else {
	// 			this.domAudio.muted = true;
	// 			// this.domAudio.currentTime = 0;
	// 			this.domAudio.pause();
	// 		}
	// 	}
	// }

    /**
     * Render layout
     * @return {jsx}
     */
    renderContent = () => {
        const { dataRound, onClickItem } = this.props;
        let maxRound = 0;
        switch (dataRound.flow) {
            case 6:
            case 7:
                maxRound = 1;
                break;
            case 8:
            case 9:
                maxRound = 2;
                break;
            case 10:
            case 11:
                maxRound = 3;
                break;
            case 12:
            case 13:
                maxRound = 4;
                break;
            case 14:
            case 15:
                maxRound = 5;
                break;
            default:
                maxRound = 6;
                break;
        }
        return [1, 2, 3, 4, 5, 6].map((indexGroupStamps, index) => {
            const displayImg = (maxRound >= indexGroupStamps) ? ('act-img imgRound-' + indexGroupStamps) : 'none-img';
            return (
                <div className="div-parent" key={index}>
                    <div
                        className={`div-child-1 ${displayImg}`}
                        onClick={(maxRound >= indexGroupStamps) ? (e) => {onClickItem(indexGroupStamps)} : undefined}
                    ></div>
                    <div 
                        className={`div-child-2 `}
                        onClick={(maxRound >= indexGroupStamps) ? (e) => {onClickItem(indexGroupStamps)} : undefined}
                    >
                        {indexGroupStamps}
                    </div>
                </div>
            )
        });
    }; 

    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { isShow, onClose } = this.props;
        return (
            <React.Fragment>
            <div className={`modal-know ${isShow ? "" : "app-hidden-modal"}`}>
                <div className="board">
                    <div className='bg-modal'>
                    <div className='div-content'>
                        {this.renderContent()}
                        <button className='btn-hide' onClick={onClose}><img src={ImgClose} alt=""/></button>
                    </div> 
                    </div>
                </div>
                <div className="container-audio">
                    {/* <audio
                        muted
                        src={AudioNiSound}
                        ref={domAudio => {this.domAudio = domAudio}}
                    /> */}
                </div>
            </div>
        </React.Fragment>
        )
    }
}

export default KnowledgeListModal;