import React from 'react';
import YaySound from '../../../assets/sounds/Apple Hill Studios - Cartoon Voices - Small Group Yay.aac';
import TeaTime from '../../../assets/sounds/Ty Simon - Tea Time.mp3';
import bgBlueLight from '../../../assets/images/12_30th_thanks/bg_blue_light.png';
import FlowersLand from '../../../assets/images/12_30th_thanks/flowers_land.png';
import Earth from '../../../assets/images/12_30th_thanks/earth.png';
import Snow from '../../../assets/images/12_30th_thanks/snow.png';
import TopClouds from '../../../assets/images/12_30th_thanks/clouds.png';
import Rainbow from '../../../assets/images/12_30th_thanks/rainbow_heart.png';
import SentenceSub from '../../../assets/images/12_30th_thanks/sentence_sub.png';
import PhraseRestored from '../../../assets/images/12_30th_thanks/phrase_restored.png';
import PhraseSaving from '../../../assets/images/12_30th_thanks/phrase_saving.png';
import PhraseThankYou from '../../../assets/images/12_30th_thanks/phrase_thankyou.png';
import bgBlue from '../../../assets/images/12_30th_thanks/bg_blue_rectangle.png';
import Duration from '../../../assets/images/12_30th_thanks/duration.svg';
// using when modal is KnowledgeModal showing
import PinkFlowersLand from '../../../assets/images/12_30th_thanks/p_flowers_land.png';
import PinkDuration from '../../../assets/images/12_30th_thanks/p_duration.svg';
import { COOKIE_NAME_ROUND } from '../../../_config/Constants';
import { initCookie, getCookie } from '../../../utils/CommonUtil';
import './12_30th_Thanks.scss';

const objDurations = {
    Duration,
    PinkDuration,
}

/**
 * Class _12_30th_Thanks (30th-earth screen)
 */
class Thanks extends React.Component {
    /**
     * Constructor
     * @param {object} props
     * @return {void}
     */
    constructor(props) {
        super(props);
        this.domSfx_12_30Thanks = null;
        this.domBgm_12_30Thanks = null;
    }

    /**
     * Lifecycle
     * Runs after the component output has been rendered to the DOM
     * @return {void}
     */
    componentDidMount(){
        const { dataRound } = this.props;
        this.domSfx_12_30Thanks.muted = dataRound.isMute;
        this.domSfx_12_30Thanks.play();  // auto play when init screen
        this.domBgm_12_30Thanks.muted = dataRound.isMute;
        // this.domBgm_12_30Thanks.pause();
        // this.domBgm_12_30Thanks.currentTime = 0;
    }
    /**
     * Lifecycle
     * Run after the component update state or props
     * @param {object} prevProps Previous props
     * @param {object} prevState Previous state
     * @return {void}
     */
    componentDidUpdate(prevProps, prevState) {
        const { dataRound } = this.props;
        if(prevProps.dataRound.flow === 19 && dataRound.flow === 2) {
            this.domSfx_12_30Thanks.pause();
            this.domSfx_12_30Thanks.currentTime = 0;

            this.domBgm_12_30Thanks.pause();
            this.domBgm_12_30Thanks.currentTime = 0;

        }
    }
    /**
     * Lifecycle
     * use when component will unmount
     * @return {void}
     */
    componentWillUnmount() {
        this.domSfx_12_30Thanks.pause();
        this.domSfx_12_30Thanks.currentTime = 0;

        this.domBgm_12_30Thanks.pause();
        this.domBgm_12_30Thanks.currentTime = 0;
    }

    /**
     * Ended audio
     * @return {void}
     */
    handleEndedAudio = () => {
        this.domSfx_12_30Thanks.pause();
        this.domSfx_12_30Thanks.currentTime = 0;
        
        this.domBgm_12_30Thanks.play();
    }

    /**
     * handle button back to start (スタートに戻る)
     * @return void
     */
    handleClickOnBackToStart = async () => {
        const { changeState, handleSfxButton } = this.props;
        const dataRound = {...this.props.dataRound};
        await handleSfxButton(2, dataRound.isMute);
        if(!navigator.onLine) return changeState({isShowErrConnectModal: true});
        const currentStateMute = dataRound.isMute;
        initCookie();
        const dataRoundNew = JSON.parse(getCookie(COOKIE_NAME_ROUND));
        dataRoundNew.flow += 1;
        dataRoundNew.isMute = currentStateMute;
        changeState({dataRound: Object.assign(dataRound, dataRoundNew)});
    }

    /**
     * handle click on day from 1 to 6
     * @return void
     */
    handleClickOnDay = (idxDate) => {
        const { changeState } = this.props;
        if(!navigator.onLine) return changeState({isShowErrConnectModal: true});
        changeState({
            groupRoundName : `groupRound${idxDate}`,
            isShowKnowledgeModal : true
        });
    }

    /**
     * function render 6 days
     * @return {array} array item element
     */
    render6Days = () => {
        const { isShowKnowledgeModal } = this.props;
        let  arrItems = [];
        for (let indexImg = 1; indexImg <= 6; indexImg++) {
            const itemImage = (!isShowKnowledgeModal) ? `Duration${indexImg}` : `PinkDuration${indexImg}`;
            const srcImg = (!isShowKnowledgeModal) ? `Duration` : `PinkDuration`;
            const element =
                (<div onClick={() => this.handleClickOnDay(indexImg)} 
                        className='grid-item' 
                        key={itemImage}>
                    <img
                        key={'img' + itemImage}
                        alt=""
                        className= 'grid-item-img'
                        src={objDurations[srcImg]}
                    />
                    <div className='grid-round'>{indexImg}</div>
                </div>)
            arrItems = [...arrItems, element];
        }
        return arrItems;
    }

    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { isShowKnowledgeModal } = this.props;
        return (
            <React.Fragment>
                <div id="div-12_30th_Thanks">
                    <div id="container-12_30th_Thanks">
                        <img
                            alt=""
                            id="bg-blue-light" 
                            src={bgBlueLight}
                        />
                        <img
                            alt=""
                            id="flowers-land"
                            src={(!isShowKnowledgeModal) ? FlowersLand : PinkFlowersLand}
                        />
                        <img
                            alt=""
                            id="earth" 
                            src={Earth}
                        />
                        <img
                            alt=""
                            id="snow" 
                            src={Snow}
                        />
                        <img
                            alt=""
                            id="top-clouds" 
                            src={TopClouds}
                        />
                        <img
                            alt=""
                            id="rainbow" 
                            src={Rainbow}
                        />
                        <div id="sentence-main">
                            <img
                                alt=""
                                id="phrase-earth-live" 
                                src={PhraseRestored}
                            />
                            <img
                                alt=""
                                id="phrase-saving" 
                                src={PhraseSaving}
                            />
                            <img
                                alt=""
                                id="phrase-thankyou" 
                                src={PhraseThankYou}
                            />
                        </div>
                        <img
                            alt=""
                            id="bg-blue" 
                            src={bgBlue}
                        /> 
                        <img
                            alt=""
                            id="sentence-sub" 
                            src={SentenceSub}
                        />
                        <div id="container-banner">
                            <label id="text-banner">豆知識</label>
                        </div>
                        <div
                            id='days-container'
                        >
                            {this.render6Days()}
                        </div>
                        <button onClick={this.handleClickOnBackToStart} id="btn-back-to-star">スタートに戻る</button>
                    </div>
                   <video
                        style={{ visibility: 'hidden'}}
                        ref={(domSfx_12_30Thanks) => { this.domSfx_12_30Thanks = domSfx_12_30Thanks }}
                        muted
                        autoPlay
                        playsInline
                        onEnded={this.handleEndedAudio}
                    >
                        <source src={YaySound} type="audio/mpeg"/>
                    </video>
                    <video
                        style={{ visibility: 'hidden'}}
                        ref={(domBgm_12_30Thanks) => { this.domBgm_12_30Thanks = domBgm_12_30Thanks }}
                        muted
                        loop
                        playsInline
                    >
                        <source src={TeaTime} type="audio/mpeg"/>
                    </video>
                </div>
            </React.Fragment>
        )
    }
}

export default Thanks;