import React from 'react';
import ErrorModal from '../../_share/home_day/error_modal/ErrorModal';
import './MasterLayout.scss';
/**
 * Function create master layout for screen
 * @param {class} TargetConmponent
 * @return {class}
 */
export function masterLayout(TargetConmponent) {

    /**
     * Class MasterLayout
     */
    class MasterLayout extends React.Component {
        /**
         * Constructor
         * @param {object} props
         * @return {void}
         */
        constructor(props) {
            super(props);
            this.domContainerPage = null;
            this.state = {
                isReadyFont: false,
                isBlur: false,
                hasError: false,
            }
            document.fonts.ready.then(() => {
                this.setState({isReadyFont: true});
            });
        }

        /**
         * Lifecycle
         * Runs after the component output has been rendered to the DOM
         * @return {void}
         */
        componentDidMount() {
            this.scalePage();
            window.addEventListener("resize", this.scalePage);
            // mobile
            if(!navigator.userAgent.includes("Windows")) {
                document.addEventListener("visibilitychange", this.visibilitychangeWeb);
                return;
            }
            // laptop
            window.addEventListener("focus", this.focusWeb);
            window.addEventListener("blur", this.blurWeb);
        }

        /**
         * Lifecycle
         * Component is being removed from the DOM
         * @return {void}
         */
        componentWillUnmount() {
            window.removeEventListener("resize", this.scalePage);
            // mobile
            if(!navigator.userAgent.includes("Windows")) {
                document.removeEventListener("visibilitychange", this.visibilitychangeWeb);
                return;
            }
            // laptop
            window.removeEventListener("focus", this.focusWeb);
            window.removeEventListener("blur", this.blurWeb);
        }

        /**
         * Get derived state from error
         * @param {object} error
         * @return {object} Data
         */
        static getDerivedStateFromError(error) {
            return { hasError : true };
        }

        /**
         * Lifecycle
         * render when component error
         * @param {object} error
         * @param {object} errorInfo
         * @return {void}
         */
        componentDidCatch(error, errorInfo) {
            console.log(error);
            console.log(errorInfo);
        }

        /**
         * Show/hide web
         * @param {event} e
         * @return {void}
         */
        visibilitychangeWeb = (e) => {
            // show
            if(document.visibilityState === "visible") {
                if(this.state.isBlur) this.setState({isBlur: false});
                return;
            }
            if(!this.state.isBlur) this.setState({isBlur: true});
        }

        /**
         * Focus web
         * @param {event} e
         * @return {void}
         */
        focusWeb = (e) => {
            if(this.state.isBlur) this.setState({isBlur: false})
        }

        /**
         * Blur web
         * @param {event} e
         * @return {void}
         */
        blurWeb = (e) => {       
            if(!this.state.isBlur) this.setState({isBlur: true}) 
        }

        /**
         * Click close button
         * @param {e} event
         * @return {void}
         */
        handleCloseErrModal = async () => {
            window.location.reload();
        }

        /**
         * Scale page
         * @return {void}
         */
        scalePage = () => {
            const width = window.outerWidth;
            this.domContainerPage.style.transform = `scale(${width/375})`;
        }

        /**
         * Lifecycle    
         * Render jsx to browser
         * @return {jsx}
         */
        render() {
            const { isReadyFont, isBlur, hasError } = this.state;
            return (
                <React.Fragment>
                    <div className="app-container-master">
                        <div className="app-container-page" style={{ overflowY : (hasError) ? 'hidden' : 'auto'}}>
                            <div 
                                className="hocs-container-page" 
                                ref={(domContainerPage) => {this.domContainerPage = domContainerPage}}
                                style={isReadyFont ? {} : { zIndex: -100000, opacity: 0, pointerEvents: "none" }}
                            >
                                {!isBlur && !hasError && <TargetConmponent />}
                            </div>
                            <ErrorModal 
                                key={'err-modal-exception'} 
                                kindError={4}
                                isShow={hasError} 
                                onClose={this.handleCloseErrModal} 
                            />
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
    return MasterLayout;
}