import ImgIcon1 from '../../../assets/images/modal_knowledge/knowledgeModal-1.svg';
import ImgIcon2 from '../../../assets/images/modal_knowledge/knowledgeModal-2.svg';
import ImgIcon3 from '../../../assets/images/modal_knowledge/knowledgeModal-3.svg';
import ImgIcon4 from '../../../assets/images/modal_knowledge/knowledgeModal-4.svg';
import ImgIcon5 from '../../../assets/images/modal_knowledge/knowledgeModal-5.svg';
import ImgIcon6 from '../../../assets/images/modal_knowledge/knowledgeModal-6.svg';

const stockData = {
    groupRound1 : {
      srcImg: ImgIcon1,
      round: "豆知識1",
      title: "地球温暖化とプラスチック",
      content: "皆さんが使っているプラスチックは、石油や石炭（化石燃料）で作られています。化石燃料を燃やすと、「二酸化炭素」という地球の温度を上げる物質が生まれます。そして、このような物質により地球の温度が上がることを、「地球温暖化」と言います。地球温暖化により、約４千種もの野生生物が絶滅の危機にあると言われています。身の回りからプラスチックを使う量を減らし、地球の温暖化を防ぐ取り組みを少しずつできるといいですね。"
    },
    groupRound2: {
        srcImg: ImgIcon2,
        round: "豆知識2",
        title: "化石燃料には限りがある",
        content: "化石燃料は、大昔に存在していた動物や植物が長い年月をかけて変化してできており、私たちが使用できる量は限られています。例えば2020年末時点では、石油はあと54年で無くなるとされています。また、化石燃料は地下深くにあるため、採掘するために周囲の自然環境を破壊してしまう可能性もあります。そのため、最近着目されているものが、再利用できるエネルギーです。どんな取り組みが行われているか調べてみましょう。"
    },
    groupRound3: {
        srcImg: ImgIcon3,
        round: "豆知識3",
        title: "海洋酸性化って何？",
        content: "地球温暖化は「海洋の酸性化」を引き起こすことが分かってきています。これは大気中の二酸化炭素を海が吸収し、酸性に変わっていくことを意味します。その結果、酸に弱い小さなプランクトンが生きることができず、食物連鎖にも影響を与えると考えられています。2100年には海の生き物たちの5分の1が消滅してしまうとの予測もあります。これからもお魚を美味しく食べ続けるため、少しずつ環境問題を意識できるといいですね。"
    },
    groupRound4: {
        srcImg: ImgIcon4,
        round: "豆知識4",
        title: "マイクロプラスチックって何？",
        content: "マイクロプラスチックは海や川などに存在する小さなプラスチックで、5mm以下のものを指します。これは、レジ袋やペットボトルなどが分解されてできています。マイクロプラスチックはサイズが小さいため、川や海に流れてしまいますが、海に流れ出たマイクロプラスチックが自然に分解するには数百年かかります。さらに、海鳥や魚が餌と勘違いして食べてしまう問題も起こっています。2050年には海のプラスチックが魚の量を超えると言われていますが、何ができるか考えてみませんか？"
    },
    groupRound5: {
        srcImg: ImgIcon5,
        round: "豆知識5",
        title: "生活排水が川や海を汚す？",
        content: "生活排水とは、台所、トイレ、お風呂など、私たちの日常生活から出る排水を言います。１日の一人当たりの排水量は250リットルにもなり、生活排水のうち最も水の汚れが高いと言われているのが台所です。生活排水は下水処理をした後に川や海に流れていきますが、日本の下水道普及率は80%で、20%は下水道を通らずに流れているのが現状です。綺麗な水を未来に残すために、私たちにできることを少しずつ考えられるといいですね。"
    },
    groupRound6: {
        srcImg: ImgIcon6,
        round: "豆知識6",
        title: "ごみを減らそう",
        content: "日本におけるプラスチック製品の廃棄量は年間850万トン以上で、一人当たりの廃棄量はアメリカに次いで世界2位だと言われています。また、食品ロス問題など、廃棄問題は私たちの身近にあります。廃棄を減らす（Reduce）、捨てずに何度も使う（Reuse）、リサイクルする（Recycle）、使い捨てを拒否する（Refuse）、直して再生させる（Repair）、の５Rの考え方も大切です。最近は廃棄されたものを活用し、モノへと生まれ変わらせる”アップサイクル”の取り組みも盛んです。"
    }
}

export default stockData;