import React from 'react';
import ImgStampsBg1 from '../../../assets/images/day_twenty_one_to_twenty_five/stamps_bg_1.svg';
import ImgStampsBg2 from '../../../assets/images/day_twenty_one_to_twenty_five/stamps_bg_2.svg';
import ImgStampsBg3 from '../../../assets/images/day_twenty_one_to_twenty_five/stamps_bg_3.svg';
import ImgStampsBg4 from '../../../assets/images/day_twenty_one_to_twenty_five/stamps_bg_4.svg';
import ImgStampsBg5 from '../../../assets/images/day_twenty_one_to_twenty_five/stamps_bg_5.svg';
import ImgStampsItem1 from '../../../assets/icons/day_twenty_one_to_twenty_five/stamps_item_1.svg';  
import ImgStampsItem2 from '../../../assets/icons/day_twenty_one_to_twenty_five/stamps_item_2.svg';  
import ImgStampsItem3 from '../../../assets/icons/day_twenty_one_to_twenty_five/stamps_item_3.svg';  
import ImgStampsItem4 from '../../../assets/icons/day_twenty_one_to_twenty_five/stamps_item_4.svg';        
import ImgStampsItem5 from '../../../assets/icons/day_twenty_one_to_twenty_five/stamps_item_5.svg';
import ImgStampsDay1Cloud1 from '../../../assets/images/day_twenty_one_to_twenty_five/stamps_cloud_day_1_1.svg';
import ImgStampsDay2Cloud1 from '../../../assets/images/day_twenty_one_to_twenty_five/stamps_cloud_day_2_1.svg';
import ImgStampsDay3Cloud1 from '../../../assets/images/day_twenty_one_to_twenty_five/stamps_cloud_day_3_1.svg';
import ImgStampsDay4Cloud1 from '../../../assets/images/day_twenty_one_to_twenty_five/stamps_cloud_day_4_1.svg';
import ImgStampsDay5Cloud1 from '../../../assets/images/day_twenty_one_to_twenty_five/stamps_cloud_day_5_1.svg';
import './DayTwentyOneToTwentyFive.scss';
/**
 * Class DayTwentyOneToTwentyFive
 */
class DayTwentyOneToTwentyFive extends React.Component {
    /**
     * Render background and image stamps
     * @return {object}
     */
    renderBackground = () => {
        const { indexStamps } = this.props;
        switch (indexStamps) {
            case 1:
                return {background: ImgStampsBg1, cloud1: ImgStampsDay1Cloud1, item: ImgStampsItem1};
            case 2:
                return {background: ImgStampsBg2, cloud1: ImgStampsDay2Cloud1, item: ImgStampsItem2};
            case 3:
                return {background: ImgStampsBg3, cloud1: ImgStampsDay3Cloud1, item: ImgStampsItem3};
            case 4:
                return {background: ImgStampsBg4, cloud1: ImgStampsDay4Cloud1, item: ImgStampsItem4}
            default:
                return {background: ImgStampsBg5, cloud1: ImgStampsDay5Cloud1, item: ImgStampsItem5};
        }
    }
    
    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { background, cloud1, item } = this.renderBackground();
        const { indexStamps } = this.props;
        return (
            <React.Fragment>
                <div
                    className="app-container-stamps container-day-twenty-tw-five"
                    style={{
                        backgroundImage: `url(${background})`
                    }}
                >
                    <div className="img-cloud-1" style={{backgroundImage: `url(${cloud1})`}}></div>
                    <div className="img-cloud-2"></div>
                    <div className="img-flower-left"></div>
                    <div className="img-flower-right"></div>
                </div>
                <div className="app-container-item-stamps-tap container-item-stamps-tap">
                    <img
                        alt=""
                        src={item}
                        draggable={false}
                        className={`app-img-item-stamps img-item-stamps-twenty-one-twenty-five-${indexStamps}`}
                    />
                 </div>
            </React.Fragment>
        )
    }
}

export default DayTwentyOneToTwentyFive;
