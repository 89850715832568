import React from 'react';
import moment from 'moment';
import { FORMAT_DATE } from '../../_config/Constants';
import ImgBgLock from '../../assets/icons/home_day_bg_clock_btn.svg';

import AudioSoundBits from '../../assets/sounds/SoundBits - Hitting - Debris Tumbling, Rock, Earth.aac';
import AudioMacaroom from '../../assets/sounds/Macaroom - Odyssey - Nostalgic Piano Phrase, Positive.mp3';
import AudioBoB from '../../assets/sounds/J.BoB - Cartoon UI - Magic Explosion.aac';
import AudioTy from '../../assets/sounds/Ty Simon - Tea Time.mp3';
import AudioStamps from '../../assets/sounds/Gamemaster&YaelHeim_Combine.mp3';
import AudioLevelUpFadeIn from '../../assets/sounds/gamemaster-audio-magic-spells-FadeIn.m4a';

import './HomeDay.scss';
/**
 * Class HomeDay
 */
class HomeDay extends React.Component {
    /**
     * Constructor
     * @param {object} props 
     * @return {void}
     */
    constructor(props) {
        super(props);
        this.domAudioFirst = null;
        this.isAudioFirstPlaying = false;
        this.isAudioFirstHandle = true;
        this.domAudioSecond = null;
        this.isAudioSecondPlaying = false;
        this.domAudioStamps = null;
        this.domAudioLevelUp = null;
        this.isAudioStampsPlaying = false;
        this.timeDelay = -100;
        this.timerNextScreenOnline = undefined;
        this.timerHideStampComponent = undefined;
        this.timerLevelUpPlaySound = undefined;
    }

    /**
     * Lifecycle
     * Runs after the component output has been rendered to the DOM
     * @return {void}
     */
    componentDidMount() {
        this.initPlayAudio();
    }

    /**
     * Lifecycle
     * Run after the component update state or props
     * @param {object} prevProps Previous props
     * @param {object} prevState Previous state
     * @return {void}
     */
    componentDidUpdate(prevProps, prevState) {
        const { dataRound } = this.props;
        
        // from cookie modal to home day
        if(prevProps.dataRound.flow === 4 && dataRound.flow === 5) {
            this.domAudioFirst.muted = dataRound.isMute;
            this.domAudioSecond.muted = dataRound.isMute;
            this.domAudioStamps.muted = dataRound.isMute;
            this.domAudioLevelUp = dataRound.isMute;
        }
        // from popup screen to main home
        if((prevProps.dataRound.flow === 4 && dataRound.flow === 5) || (dataRound.roundCr === 0 && dataRound.roundCr !== prevProps.dataRound.roundCr)) {
            // this.domAudioFirst.currentTime = 0;
            this.domAudioFirst.muted = dataRound.isMute;
            if(this.isAudioSecondPlaying) {
                this.domAudioSecond.pause();
                this.domAudioSecond.currentTime = 0;

                this.isAudioSecondPlaying = false;
            }
            if(!this.isAudioFirstPlaying) this.domAudioFirst.play().then(() => {this.isAudioFirstPlaying = true;});
            this.isAudioFirstHandle = true;
        }
    }

    /**
     * Lifecycle
     * use when component will unmount
     * @return {void}
     */
    componentWillUnmount() {
        clearTimeout(this.timerNextScreenOnline);
        clearTimeout(this.timerHideStampComponent);
        clearTimeout(this.timerPlayAudioBg);
        clearTimeout(this.timerLevelUpPlaySound);
        this.domAudioSecond.pause();
        this.domAudioSecond.currentTime = 0;
        this.isAudioSecondPlaying = false;
    }

    /**
     * Init play audio
     * @return {void}
     */
    initPlayAudio = async () => {
        const { dataRound } = this.props;
        if(dataRound.flow >= 5 && dataRound.flow < 17) {
            this.domAudioFirst.muted = dataRound.isMute;
            this.domAudioSecond.muted = dataRound.isMute;
            this.domAudioStamps.muted = dataRound.isMute;
            this.domAudioLevelUp.muted = dataRound.isMute;
            // this.domAudioFirst.currentTime = 0;
            if(!this.isAudioFirstPlaying) {
                await this.domAudioFirst.play();
                this.isAudioFirstPlaying = true;
                this.isAudioFirstHandle = true;
            }
        }
    }

    /**
     * Ended audio first
     * @param {event} e
     * @return {void} 
     */
    handleEndedAudioFirst = async (e) => {
        const { dataRound } = this.props;
        // this.domAudioSecond.currentTime = 0;
        this.domAudioSecond.muted = dataRound.isMute;

        this.domAudioFirst.pause();
        this.domAudioFirst.currentTime = 0
        this.isAudioFirstPlaying = false;
        
        if(!this.isAudioSecondPlaying) await this.domAudioSecond.play();
        this.isAudioSecondPlaying = true;
        this.isAudioFirstHandle = false;
    }

    /**
     * Toogle status audio
     * @return {void}
     */
    handleClicktoggleAudio = async () => {
        const { handleClicktoggleAudio } = this.props;
        handleClicktoggleAudio(
            () => {
                if(this.isAudioFirstPlaying) {
                    this.domAudioFirst.pause();
                    this.domAudioFirst.currentTime = 0;
                }
                if(this.isAudioSecondPlaying) {
                    this.domAudioSecond.pause();
                    this.domAudioSecond.currentTime = 0;
                }
                this.isAudioFirstPlaying = false;
                this.isAudioSecondPlaying = false;
            },
            async (isMute) => {try {
                // alert(isMute)
                this.domAudioFirst.muted = isMute;
                this.domAudioSecond.muted = isMute;
                this.domAudioStamps.muted = isMute;
                this.domAudioLevelUp.muted = isMute;
                if(this.isAudioFirstHandle && !this.isAudioFirstPlaying) {
                    this.domAudioFirst.play();
                    this.isAudioFirstPlaying = true;
                }
                else if(!this.isAudioFirstHandle && !this.isAudioSecondPlaying) {
                    this.domAudioSecond.play();
                    this.isAudioSecondPlaying = true;
                }
            } catch (error) {
                // alert(error.em)
            }
               
            }
        );
    }

    /**
     * Click stamps button
     * @param {indexStamps}
     * @return {void}
     */
    handleClickStamps = async (indexStamps) => {
        const { dataRound, changeState } = this.props;
        if((indexStamps - dataRound.roundCr) > 1) return;
        if(!navigator.onLine) return changeState({isShowErrConnectModal: true});
        const { createAt, countRoundToDay } = dataRound;
        const crDate = moment();
        const clickLasterDate = moment(createAt, FORMAT_DATE);
        // A stamp is pressed 3 times a day, each time is limited to 1 hour, 
        // if pressed earlier (more than 1 time but less than 1 hour apart) or more than 3 times will show an error message
        const isSameDay = crDate.format("YYYY/MM/DD") === clickLasterDate.format("YYYY/MM/DD");
        if(process.env.REACT_APP_IS_CHECK_VALIDATE === "true") {
            const distanceTime = crDate.diff(clickLasterDate, "hours");
            // if(
            //     crDate.isSameOrBefore(clickLasterDate)
            //     ||  (isSameDay && (countRoundToDay >= 3 || distanceTime < 1))
            // ) {
            //     changeState({isShowErr3Times1DayModal: true});
            //     return;
            // }
            if(isSameDay && countRoundToDay >= 3){
                changeState({isShowErr3Times1DayModal: true});
                return;
            }
            
            if(isSameDay && distanceTime < 1){
                changeState({isShowErr1Times1HourModal: true});
                return;
            }
        }

        // error play video with settimeout chorm in ios
        if(this.isAudioFirstPlaying) {
            this.domAudioFirst.pause();
            // this.domAudioFirst.currentTime = 0;
        }
        if(this.isAudioSecondPlaying) {
            this.domAudioSecond.pause();
            // this.domAudioSecond.currentTime = 0;
        }
        this.isAudioFirstPlaying = false;
        this.isAudioSecondPlaying = false;

        // this.domAudioStamps.currentTime = 0;
        if(!this.isAudioStampsPlaying) {
            this.domAudioStamps.play();
            this.isAudioStampsPlaying = true;
        } 
        // show popup stamps
        changeState({ indexStamps: indexStamps});
        this.timerHideStampComponent = setTimeout(() => {
            if(!isSameDay) dataRound.countRoundToDay = 0;
            dataRound.countRoundToDay += 1;
            dataRound.roundCr += 1;
            dataRound.createAt = crDate.format("YYYY/MM/DD HH:mm:ss");

            if(this.isAudioStampsPlaying) {
                if (dataRound.roundCr !== 5) { 
                    this.domAudioStamps.pause();
                    this.domAudioStamps.currentTime = 0;
                }
                this.isAudioStampsPlaying = false;
            }
            changeState({dataRound});
            this.timerNextScreenOnline = setTimeout(async () => {
                if(dataRound.roundCr === 5) {
                    dataRound.flow += 1;
                    dataRound.roundCr = -1;
                    changeState({dataRound, indexStamps: -1, isShowKnowledgeModal: true, groupRoundName: ""});

                    this.domAudioLevelUp.muted = dataRound.isMute;
                    this.domAudioLevelUp.play();

                    // level up is here
                    this.timerLevelUpPlaySound = setTimeout( async () => {
                        if(this.isAudioFirstHandle) {
                            this.domAudioFirst.play()
                            this.isAudioFirstPlaying = true;
                        }
                        else {
                            this.domAudioSecond.play();
                            this.isAudioSecondPlaying = true;
                        }
                    }, 1700)
                }
                else {
                    if(this.isAudioFirstHandle) this.domAudioFirst.play().then(() => {this.isAudioFirstPlaying = true;});
                    else this.domAudioSecond.play().then(() => {this.isAudioSecondPlaying = true;});
                    // J.BoB - Cartoon UI - Collect Prize.aac time is 1000
                    changeState({indexStamps: -1});
                }
            }, 300);

        }, 4500 + this.timeDelay);
    }

    /**
     * Render button working
     * @return {jsx}
     */
    renderBtnStamps = () => {
        const { itemUnlock } = this.props;
        const { roundCr } = this.props.dataRound;
        return (
            [1, 2, 3, 4, 5].map((stamp, index) => {
                return (
                    (roundCr >= stamp) ?   
                    (
                        <img 
                            alt="" 
                            key={index}
                            src={itemUnlock[stamp-1]}
                            className="btn-stamps"
                            draggable={false}
                            style={{
                                cursor: "default"
                            }}
                        /> 
                    ) : 
                    (
                        <div
                            key={index}
                            className="btn-stamps"
                            style={{
                                backgroundImage: `url(${ImgBgLock})`,
                            }}
                            onClick={() => { this.handleClickStamps(stamp)}}
                        >
                            {this.getStampsIndex(stamp)}
                        </div>
                    )
                )
            })
        )
    }

    /**
     * Get stamps index
     * @param {number} stamps
     * @return {number} 
     */
    getStampsIndex = (stamp) => {
        const { flow } = this.props.dataRound;
        switch (flow) {
            case 3:
            case 4:
            case 5:
            case 6:
                return 0 + stamp;
            case 7:
            case 8:
                return 5 + stamp;
            case 9:
            case 10:
                return 10 + stamp;
            case 11:
            case 12:
                return 15 + stamp;
            case 13:
            case 14:
                return 20 + stamp;
            case 15:
            case 16:
                return 25 + stamp;
            default:
                break;
        }
    }

    /**
     * Render home day
     * @param {boolean} isShowPopup
     * @return {jsx}
     */
    renderHomeDay = (isShowPopup) => {
        const { backgroundColor, cloudWave, title, earth, componentStamps: ComponentStamps, dataRound, indexStamps, handleClickTrivia, handleClickSoap, handClickDishTowel, handleClickCookie } = this.props;
        const { flow, isMute } = dataRound;
        const bgPositionX = {};
        if(dataRound.flow === 7 || dataRound.flow === 8) bgPositionX.backgroundPositionX = "calc(50% + 14px)";
        return (
            <React.Fragment>
                <div className={`container-home-day ${isShowPopup ? "remove-animation" : ""}`} >
                    <div style={{
                        position: "absolute",
                        width: "calc(100% + 10px)",
                        height: "100%",
                        marginLeft: "-2px",
                        backgroundColor: backgroundColor
                    }}></div>
                    <div className="content-home-day">
                        <div className={`cloud-wave ${cloudWave}`} style={{...bgPositionX}}></div>
                        <div className="container-layout">
                            <div className="container-knowledge-speaker">
                                {
                                    flow > 6 &&
                                    <div className="container-knowledge" onClick={handleClickTrivia} ></div>
                                }
                                <div
                                    className={`container-speaker ${!isMute ? "speaker-on" : "speaker-off"}`}
                                    onClick={this.handleClicktoggleAudio}
                                ></div>
                            </div>
                            <div className={`container-guidance ${title}`}></div>
                            <div className={`container-earth ${earth}`}></div>
                            <div className="container-item-stamps-descript">
                                <div className="container-item-stamps">
                                    <div className="content-item-stamps">
                                        {this.renderBtnStamps()}
                                    </div>
                                </div>
                                <div className="container-descript">
                                    <div 
                                        className="container-soap"
                                        id="container-soap"
                                        onClick={handleClickSoap}
                                    >
                                        メグループ石けんの説明
                                    </div>
                                    <div
                                        className="container-dish-towel"
                                        id="container-dish-towel"
                                        onClick={handClickDishTowel}
                                    >
                                        メグループふきんの説明
                                    </div>
                                </div>
                                <div className="container-note">
                                    <div
                                        className="content-note"
                                        id="content-note"
                                        onClick={handleClickCookie}
                                    >
                                        注意事項
                                    </div>
                                </div>
                            </div>
                            <div id="container-modal">
                                {indexStamps !== -1 && <ComponentStamps indexStamps={indexStamps} />}
                            </div>
                        </div>
                        
                    </div>
                    
                    
                </div>
            </React.Fragment>
        )
    }

    /**
     * Lifecycle
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { dataRound } = this.props;
        // show HOME - DAY 1 TO 30 (have animation)
        return (
            <React.Fragment>
                {
                    dataRound.flow < 5 || [6, 8, 10, 12, 14, 16].includes(dataRound.flow) ?
                    // show poup (no animation)
                    <div className="container-page-poup">
                        {this.renderHomeDay(true)}
                        
                    </div> :
                    this.renderHomeDay(false)
                }
                <div className="container-audio">
                        {/* When you enter the stamp card screen, it changes to another BGM. BGM from day 1 to day 15 (include 2 music sounds, in order)
                        SoundBits - Hitting - Debris Tumbling, Rock, Earth → Macaroom - Odyssey - Nostalgic Piano Phrase, Positive
                        When you enter the stamp card screen, it changes to another BGM. BGM from day 16 to day 30 (include 2 music sounds, in order)
                        J.BoB - Cartoon UI - Magic Explosion → Ty Simon - Tea Time */}
                        {
                            dataRound.flow < 11 ?
                            <React.Fragment>
                                <video
                                    style={{ visibility: 'hidden'}}
                                    ref={(domAudioFirst) => { this.domAudioFirst = domAudioFirst }}
                                    muted
                                    playsInline
                                    preload="auto"
                                    onEnded={this.handleEndedAudioFirst}
                                >
                                    <source src={AudioSoundBits} type="audio/mpeg"/>
                                </video>
                                <video
                                    style={{ visibility: "hidden" }}
                                    ref={(domAudioSecond) => { this.domAudioSecond = domAudioSecond }}
                                    muted
                                    playsInline
                                    preload="auto"
                                    loop
                                >
                                    <source src={AudioMacaroom} type="audio/mpeg"/>
                                </video>
                            </React.Fragment> :
                            <div>
                                <video
                                    style={{ visibility: 'hidden'}}
                                    ref={(domAudioFirst) => { this.domAudioFirst = domAudioFirst }}
                                    muted
                                    playsInline
                                    preload="auto"
                                    onEnded={this.handleEndedAudioFirst}
                                >
                                    <source src={AudioBoB} type="audio/mpeg"/>
                                </video>
                                <video
                                    style={{ visibility: "hidden" }}
                                    ref={(domAudioSecond) => { this.domAudioSecond = domAudioSecond }}
                                    muted
                                    playsInline
                                    preload="auto"
                                    loop
                                >
                                    <source src={AudioTy} type="audio/mpeg"/>
                                </video>
                            </div>
                        }
                    
                        <audio
                            ref={(domAudioStamps) => { this.domAudioStamps = domAudioStamps }}
                            muted
                            src={ AudioStamps }
                        />
                        <audio 
                            ref={(domAudioLevelUp) => {this.domAudioLevelUp = domAudioLevelUp}}
                            style={{visibility:'hidden'}}
                            muted
                            src={AudioLevelUpFadeIn}
                            controls
                        /> 
                </div>
            </React.Fragment>
        );
    }
}

export default HomeDay;