import moment from 'moment/moment';
import { COOKIE_NAME_ROUND, COOKIE_NAME_EXPIRES, FORMAT_DATE } from '../_config/Constants';

/**
 * Init cookie
 * @return {void}
 */
export const initCookie = () => {
    const date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = COOKIE_NAME_EXPIRES + "=" + date.getTime() + ";" + expires + ";path=/";
    const dataCookie = {
        roundCr: 0,
        flow: 1,
        createAt: moment(new Date()).subtract(10, "hours").format(FORMAT_DATE),
        countRoundToDay: 0
    }
    document.cookie = COOKIE_NAME_ROUND + "=" + JSON.stringify(dataCookie) + ";" + expires + ";path=/"
}

/**
 * Update cookie
 * @param {string} cookieName 
 * @param {string} cookieValue
 * @return {void} 
 */
export const updateCookie = (cookieName, cookieValue) => {
    if(!checkCookie(cookieName)) initCookie();
    const dateTime = getCookie(COOKIE_NAME_EXPIRES);
    const date = new Date();
    date.setTime(+dateTime);
    const expires = "expires=" + date.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

/**
 * Get cookie
 * @param {string} cookieName 
 * @return {string}
 */
export const getCookie = (cookieName) => {
    const name = cookieName + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

/**
 * Check cookie
 * @param {string} cookieName 
 * @return {boolean}
 */
export const checkCookie = (cookieName) => {
    const data = getCookie(cookieName);
    return data !== null;
}

/**
 * Delete cookie
 * @return {void}
 */
export const deleteCookie = () => {
    const date = new Date();
    date.setHours(date.getHours() - 1);
    const expires = "expires=" + date.toUTCString();
    document.cookie = COOKIE_NAME_EXPIRES + "=;" + expires + ";path=/";
    document.cookie = COOKIE_NAME_ROUND + "=;" + expires + ";path=/"
}

/**
 * Fade in audio
 * @param {object} domAudio
 * @return {number} 
 */
export const fadeInAudio = (domAudio) => {
    if(!domAudio) return undefined;
    domAudio.volume = 0;
    domAudio.play();
    const timer = setInterval(() => {
        domAudio.volume = (domAudio.volume*10 + 1)/10;
        if(domAudio.volume === 1) clearInterval(timer);
    }, 100);
    return timer;
}