// one to five
import ImgOneToFiveStamItemUnlock1 from '../assets/icons/day_one_to_five/stamps_item_1.svg';
import ImgOneToFiveStamItemUnlock2 from '../assets/icons/day_one_to_five/stamps_item_2.svg';
import ImgOneToFiveStamItemUnlock3 from '../assets/icons/day_one_to_five/stamps_item_3.svg';
import ImgOneToFiveStamItemUnlock4 from '../assets/icons/day_one_to_five/stamps_item_4.svg';
import ImgOneToFiveStamItemUnlock5 from '../assets/icons/day_one_to_five/stamps_item_5.svg';
import DayOneToFive from '../components/home/day_one_to_five/DayOneToFive';
// six to ten
import ImgSixToTenStamItemUnlock1 from '../assets/icons/day_six_to_ten/stamps_item_1.svg';
import ImgSixToTenStamItemUnlock2 from '../assets/icons/day_six_to_ten/stamps_item_2.svg';
import ImgSixToTenStamItemUnlock3 from '../assets/icons/day_six_to_ten/stamps_item_3.svg';
import ImgSixToTenStamItemUnlock4 from '../assets/icons/day_six_to_ten/stamps_item_4.svg';
import ImgSixToTenStamItemUnlock5 from '../assets/icons/day_six_to_ten/stamps_item_5.svg';
import DaySixToTen from '../components/home/day_six_to_ten/DaySixToTen';
// eleven to fifteen
import ImgElevenToFifteenStamItemUnlock1 from '../assets/icons/day_eleven_to_fifteen/stamps_item_1.svg';
import ImgElevenToFifteenStamItemUnlock2 from '../assets/icons/day_eleven_to_fifteen/stamps_item_2.svg';
import ImgElevenToFifteenStamItemUnlock3 from '../assets/icons/day_eleven_to_fifteen/stamps_item_3.svg';
import ImgElevenToFifteenStamItemUnlock4 from '../assets/icons/day_eleven_to_fifteen/stamps_item_4.svg';
import ImgElevenToFifteenStamItemUnlock5 from '../assets/icons/day_eleven_to_fifteen/stamps_item_5.svg';
import DayElevenToFifteen from '../components/home/day_eleven_to_fifteen/DayElevenToFifteen';
// sixteen to twenty
import ImgSixteenToTwentyStamItemUnlock1 from '../assets/icons/day_sixteen_to_twenty/stamps_item_1.svg';
import ImgSixteenToTwentyStamItemUnlock2 from '../assets/icons/day_sixteen_to_twenty/stamps_item_2.svg';
import ImgSixteenToTwentyStamItemUnlock3 from '../assets/icons/day_sixteen_to_twenty/stamps_item_3.svg';
import ImgSixteenToTwentyStamItemUnlock4 from '../assets/icons/day_sixteen_to_twenty/stamps_item_4.svg';
import ImgSixteenToTwentyStamItemUnlock5 from '../assets/icons/day_sixteen_to_twenty/stamps_item_5.svg';
import DaySixteenToTwenty from '../components/home/day_sixteen_to_twenty/DaySixteenToTwenty';
// twenty one to twenty five
import ImgTwentyOneToTwentyFiveStamItemUnlock1 from '../assets/icons/day_twenty_one_to_twenty_five/stamps_item_1.svg';
import ImgTwentyOneToTwentyFiveStamItemUnlock2 from '../assets/icons/day_twenty_one_to_twenty_five/stamps_item_2.svg';
import ImgTwentyOneToTwentyFiveStamItemUnlock3 from '../assets/icons/day_twenty_one_to_twenty_five/stamps_item_3.svg';
import ImgTwentyOneToTwentyFiveStamItemUnlock4 from '../assets/icons/day_twenty_one_to_twenty_five/stamps_item_4.svg';
import ImgTwentyOneToTwentyFiveStamItemUnlock5 from '../assets/icons/day_twenty_one_to_twenty_five/stamps_item_5.svg';
import DayTwentyOneToTwentyFive from '../components/home/day_twenty_one_to_twenty_five/DayTwentyOneToTwentyFive';
// twenty six to thirty
import ImgTwentySixToThirtyStamItemUnlock1 from '../assets/icons/day_twenty_six_to_thirty/stamps_item_1.svg';
import ImgTwentySixToThirtyStamItemUnlock2 from '../assets/icons/day_twenty_six_to_thirty/stamps_item_2.svg';
import ImgTwentySixToThirtyStamItemUnlock3 from '../assets/icons/day_twenty_six_to_thirty/stamps_item_3.svg';
import ImgTwentySixToThirtyStamItemUnlock4 from '../assets/icons/day_twenty_six_to_thirty/stamps_item_4.svg';
import ImgTwentySixToThirtyStamItemUnlock5 from '../assets/icons/day_twenty_six_to_thirty/stamps_item_5.svg';
import DayTwentySixToThirty from '../components/home/day_twenty_six_to_thirty/DayTwentySixToThirty';

export const COOKIE_NAME_ROUND = "roundEarth";
export const COOKIE_NAME_EXPIRES = "cookieExpires";
export const FORMAT_DATE = "YYYY/MM/DD HH:mm:ss";
export const TIMER_RECHECK_ONLINE = 500;
export const DATA_HOME_DAY = {
    DAY_ONE_TO_FIVE: {
        backgroundColor: "#00396E",
        cloudWave: "cloud-wave-1",
        title: "title-1",
        earth: "earth-1",
        itemUnlock: [
            ImgOneToFiveStamItemUnlock1, 
            ImgOneToFiveStamItemUnlock2, 
            ImgOneToFiveStamItemUnlock3,
            ImgOneToFiveStamItemUnlock4,
            ImgOneToFiveStamItemUnlock5
        ],
        componentStamps: DayOneToFive,
    },
    DAY_SIX_TO_TEN: {
        backgroundColor: "#0077B6",
        cloudWave: "cloud-wave-2",
        title: "title-2",
        earth: "earth-2",
        itemUnlock: [
            ImgSixToTenStamItemUnlock1, 
            ImgSixToTenStamItemUnlock2, 
            ImgSixToTenStamItemUnlock3,
            ImgSixToTenStamItemUnlock4,
            ImgSixToTenStamItemUnlock5
        ],
        componentStamps: DaySixToTen,
    },
    DAY_ELEVENT_TO_FIFTEEN: {
        backgroundColor: "#48CAE4",
        cloudWave: "cloud-wave-3",
        title: "title-3",
        earth: "earth-3",
        itemUnlock: [
            ImgElevenToFifteenStamItemUnlock1, 
            ImgElevenToFifteenStamItemUnlock2, 
            ImgElevenToFifteenStamItemUnlock3, 
            ImgElevenToFifteenStamItemUnlock4, 
            ImgElevenToFifteenStamItemUnlock5
        ],
        componentStamps: DayElevenToFifteen,
    },
    DAY_SIXTEEN_TO_TWENTY: {
        backgroundColor: "#ADE8F4",
        cloudWave: "cloud-wave-4",
        title: "title-4",
        earth: "earth-4",
        itemUnlock: [
            ImgSixteenToTwentyStamItemUnlock1, 
            ImgSixteenToTwentyStamItemUnlock2, 
            ImgSixteenToTwentyStamItemUnlock3, 
            ImgSixteenToTwentyStamItemUnlock4, 
            ImgSixteenToTwentyStamItemUnlock5
        ],
        componentStamps: DaySixteenToTwenty,
    },
    DAY_TWENTY_ONE_TO_TWENTY_FIVE: {
        backgroundColor: "#CDF0EA",
        cloudWave: "cloud-wave-5",
        title: "title-5",
        earth: "earth-5",
        itemUnlock: [
            ImgTwentyOneToTwentyFiveStamItemUnlock1, 
            ImgTwentyOneToTwentyFiveStamItemUnlock2, 
            ImgTwentyOneToTwentyFiveStamItemUnlock3, 
            ImgTwentyOneToTwentyFiveStamItemUnlock4, 
            ImgTwentyOneToTwentyFiveStamItemUnlock5
        ],
        componentStamps: DayTwentyOneToTwentyFive,
    },
    DAY_TWENTY_SIX_TO_THIRTY: {
        backgroundColor: "#FFFFFF",
        cloudWave: "cloud-wave-6",
        title: "title-6",
        earth: "earth-6",
        itemUnlock: [
            ImgTwentySixToThirtyStamItemUnlock1, 
            ImgTwentySixToThirtyStamItemUnlock2, 
            ImgTwentySixToThirtyStamItemUnlock3, 
            ImgTwentySixToThirtyStamItemUnlock4, 
            ImgTwentySixToThirtyStamItemUnlock5
        ],
        componentStamps: DayTwentySixToThirty,
    }

}