import React from 'react';
import ImgStampsBg1 from '../../../assets/images/day_one_to_five/stamps_bg_1.svg';
import ImgStampsBg2 from '../../../assets/images/day_one_to_five/stamps_bg_2.svg';
import ImgStampsBg3 from '../../../assets/images/day_one_to_five/stamps_bg_3.svg';
import ImgStampsBg4 from '../../../assets/images/day_one_to_five/stamps_bg_4.svg';
import ImgStampsBg5 from '../../../assets/images/day_one_to_five/stamps_bg_5.svg';
import ImgStampsItem1 from '../../../assets/icons/day_one_to_five/stamps_item_1.svg';  
import ImgStampsItem2 from '../../../assets/icons/day_one_to_five/stamps_item_2.svg';  
import ImgStampsItem3 from '../../../assets/icons/day_one_to_five/stamps_item_3.svg';  
import ImgStampsItem4 from '../../../assets/icons/day_one_to_five/stamps_item_4.svg';        
import ImgStampsItem5 from '../../../assets/icons/day_one_to_five/stamps_item_5.svg';  
import './DayOneToFive.scss';
/**
 * Class DayOneToFive
 */
class DayOneToFive extends React.Component {
    /**
     * Render background and image stamps
     * @return {object}
     */
    renderBackground = () => {
        const { indexStamps } = this.props;
        switch (indexStamps) {
            case 1:
                return {background: ImgStampsBg1, item: ImgStampsItem1};
            case 2:
                return {background: ImgStampsBg2, item: ImgStampsItem2};
            case 3:
                return {background: ImgStampsBg3, item: ImgStampsItem3};
            case 4:
                return {background: ImgStampsBg4, item: ImgStampsItem4}
            default:
                return {background: ImgStampsBg5, item: ImgStampsItem5};
        }
    }
    
    /**
     * Lifecycle
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { background, item } = this.renderBackground();
        const { indexStamps } = this.props;
        return (
            <React.Fragment>
                <div
                    className="app-container-stamps container-day-one-five"
                    style={{
                        backgroundImage: `url(${background})`
                    }}
                >
                    <div className="img-pannel-left"></div>
                    <div className="img-pannel-right"></div>
                    <div className="bg-star"></div>
                </div>
                <div className="app-container-item-stamps-tap container-item-stamps-tap">
                    <img
                        alt=""
                        src={item}
                        draggable={false}
                        className={`app-img-item-stamps img-item-stamps-one-five-${indexStamps}`}
                    />
                 </div>
            </React.Fragment>
        )
    }
}

export default DayOneToFive;
