import React from 'react';
import ImgEarth from '../../../assets/images/start/earth-group-login.png';
import { TIMER_RECHECK_ONLINE } from '../../../_config/Constants';
import './Splash.scss';
/**
 * Class Splash (0-Splash screen)
 */
class Splash extends React.Component {
    /**
     * Constructor
     * @param {object} props
     * @return {void}
     */
    constructor(props) {
        super(props);
        this.countdown = undefined;
        this.timerNextScreen = undefined;
    }

    /**
     * Lifecycle
     * runs after the component output has been rendered to the DOM
     * @return {void}
     */
    componentDidMount() {
        const { changeState } = this.props;
        this.countdown = setTimeout(() => {
            if(!navigator.onLine) return changeState({isShowErrConnectModal: true});
            const dataRound = {...this.props.dataRound};
            dataRound.flow += 1;
            changeState({dataRound});
        }, 6000);
    }

    /**
     * Lifecycle
     * Run after the component update state or props
     * @param {object} prevProps Previous props
     * @param {object} prevState Previous state
     * @return {void}
     */
    componentDidUpdate(prevProps, prevState) {
        clearTimeout(this.timerNextScreen);
        const { isShowErrConnectModal, changeState } = this.props;
        if(!isShowErrConnectModal && prevProps.isShowErrConnectModal) {
            this.timerNextScreen = setTimeout(() => {
                if(navigator.onLine) {
                    const dataRound = {...this.props.dataRound};
                    dataRound.flow += 1;
                    changeState({dataRound});
                    return;
                }
                changeState({isShowErrConnectModal: true});
            }, TIMER_RECHECK_ONLINE) 
        }
    }
    
    /**
     * Lifecycle
     * use when component will unmount
     * @return {void}
     */
    componentWillUnmount() {
        clearTimeout(this.countdown);
        clearTimeout(this.timerNextScreen);
    }

    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        return (
            <React.Fragment>
                <div className="container-splash">
                    <div className="aniEarth">
                        <img src={ImgEarth} alt=""/>
                    </div> 
                </div>  
            </React.Fragment>
        )
    }
}

export default Splash;