import React from 'react';
import imgClose from '../../../assets/icons/close.png';
import './ReviewKnowledge.scss';
/**
 * Class ReviewKnowledge
 */
class ReviewKnowledge extends React.Component {
    /**
     * Render content modal
     * @returns {jsx}
     */
    renderContent = () => {
        const { isShowOverview } = this.props;
        if(isShowOverview) {
            return (
                <React.Fragment>
                    <span className='content'>私たちの生活から出る</span>
                    <span className='txt-green'>CO2、排水、ゴミ</span>
                    <span className='content'>で地球がどんどん汚くなっているよ。このままだと、</span>
                    <span className='txt-blue'>人間、動物、お魚、植物など、</span>
                    <span className='content'>どの生き物も住めない地球になっちゃうよ。けど、君のがんばりで地球を救えるかも！？メグループの石けんとふきんを使って、お皿を洗うお手伝いをしよう！お皿を洗い終わったら、スタンプを1個押せるよ。</span>
                    <span className='txt-pink'>30回スタンプを押すと</span>、
                    <span className='content'>きれいな地球を取り戻せるよ。がんばって地球をきれいにしよう！</span>
                </React.Fragment>
            ) 
        }

        return ( 
            <React.Fragment>
                <span className='content'>
                <span className='txt-title'>注意事項</span><br />
                ・このブラウザゲームは<span className='txt-pink'>Cookie</span>を<br />
                    使ってスタンプを保存します。同じ<br />
                    デバイス（スマートフォン、タブ<br />
                    レット、パソコンなど）を使用しないとスタンプが保持されません。<br />
                    ・ブラウザがプライベートモードだ<br />
                    とスタンプが保持されません。<br />
                    ・<span className='txt-pink'>Cookie</span>を削除するとスタンプの<br />
                    記録も消えますのでご注意ください。
                </span>
            </React.Fragment>
            ) 
    }

    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { isShow, isShowOverview, onClose } = this.props;
        return (
            <React.Fragment>
                <div className={`modal-fade ${isShow ? "" : "app-hidden-modal"}`}>
                    <div className="board">
                    <button 
                        className='btn-hide' 
                        onClick={onClose}
                    >
                        <img src={imgClose} alt=""/>
                    </button>
                    <div className={`bg-modal ${!isShowOverview ? "bg-modal-cookie" : ""}`}>
                        <div className="content-modal" id='style-scroll'>
                            <div className='display-modal'>
                                {this.renderContent()}
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ReviewKnowledge;