import React from 'react';
import images from './ImportPicture';
import CongrasSound from '../../../assets/sounds/Epic Stock Media - Explainer Video - Ta Da, Positive Notification Fanfare.aac';
import TeaTime from '../../../assets/sounds/Ty Simon - Tea Time.mp3';
import './Completed.scss';

/**
 * Class Complete (30th-earth screen)
 */
class Complete extends React.Component {
    /**
     * Constructor
     * @param {object} props
     * @return {void}
     */
    constructor(props) {
        super(props);
        this.domBgmCompleted = null;
        this.bgmCompletedEnd = false;
        this.domBgmMain = null;
        this.timerNextScreen = undefined;
        // this.timerPlayBgmMain = undefined;
    }

    /**
     * Lifecycle
     * Runs after the component output has been rendered to the DOM
     * @return {void}
     */
    componentDidMount(){
		// const { dataRound, changeState } = this.props;
		const { dataRound } = this.props;
		this.domBgmCompleted.muted = dataRound.isMute;
		this.domBgmCompleted.play();

		
        // this.timerNextScreen = setTimeout(() => {
        //     this.domBgmMain.pause();
        //     this.domBgmMain.currentTime = 0;
        //     changeState({dataRound: {...dataRound, flow: dataRound.flow + 1}});
        // }, 10000);  // delay 8s + 2s Tada sound to auto move next screen
    }

    /**
     * Lifecycle
     * Run after the component update state or props
     * @param {object} prevProps Previous props
     * @param {object} prevState Previous state
     * @return {void}
     */
    componentDidUpdate(prevProps, prevState) {
        const { dataRound } = this.props;
        if(prevProps.dataRound.flow === 17 && dataRound.flow === 18) {
            this.domBgmCompleted.pause();
            this.domBgmCompleted.currentTime = 0;

            this.domBgmMain.pause();
            this.domBgmMain.currentTime = 0;

        }
    }

    /**
     * Lifecycle
     * use when component will unmount
     * @return {void}
     */
     componentWillUnmount() {
        this.domBgmCompleted.pause();
        this.domBgmCompleted.currentTime = 0;

        this.domBgmMain.pause();
        this.domBgmMain.currentTime = 0;

        clearTimeout(this.timerNextScreen);
        clearInterval(this.fadeInAudio);
        // clearTimeout(this.timerPlayBgmMain);
    }

    /**
     * handle event on end
     * @return {void}
     */
    handleOnEndAudioCompleted = () => {
		const { dataRound } = this.props;
        this.bgmCompletedEnd = true;

        this.domBgmCompleted.pause();
        this.domBgmCompleted.currentTime = 0;

        // this.timerPlayBgmMain = setTimeout(() => {
            const { isMute } = dataRound;
            // this.domBgmMain.currentTime = 0;
            this.domBgmMain.muted = isMute;
            this.domBgmMain.volume = 0.0;
            this.domBgmMain.play();

            this.fadeInAudio = setInterval(() => {
                const fadePoint = 0.5;
                if(this.domBgmMain){
                    if ((this.domBgmMain.currentTime <= fadePoint)) {
                        this.domBgmMain.volume += 0.05;
                    }
                    if (this.domBgmMain.volume > 0.5) {
                      clearInterval(this.fadeInAudio);
                    }
                }
            }, 0.05); // fade-in 0.5s sound - 20 distance
        // }, 1000); // delay 1s to play sound
    }

    /**
     * Toogle status audio
     * @return {void}
     */
    handleClicktoggleAudio = () => {
        const { handleClicktoggleAudio, dataRound } = this.props;
        // this.domBgmCompleted.pause();
        // this.domBgmCompleted.currentTime = 0;

        const flgMuted = !dataRound.isMute;
        handleClicktoggleAudio(
            () => {},
            () => {
                this.domBgmCompleted.muted = flgMuted;
                this.domBgmMain.muted = flgMuted;
            }
        );
    }

    /**
     * function render 30 item achievement
     * @return {Array} array item element
     */
    render30ItemsAchievement = () => {
        const arrItems = [];
        for(var indexRow = 1; indexRow <= 6; indexRow++){
            for (let indexImg = 1; indexImg <= 5; indexImg++) {
                const itemImage = `row${indexRow}_${indexImg}`;
                const element = 
                (<div className='grid-item' key={itemImage}>
                    <img 
                        key={'img'+itemImage}
                        alt=""
                        className='grid-item-img'
                        src={images[itemImage]}
                    />
                </div>)
            arrItems.push(element);
            }
        }
        return arrItems;
    }
    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { isMute } = this.props.dataRound;
        return (
            <React.Fragment>
                <div id="div-10-completed">
                    <div id="container-completed">
                        <div
                            className="container-speaker"
                            onClick={()=>this.handleClicktoggleAudio()}
                        >
                            <img
                                alt=""
                                id="10-completed-speaker"
                                src={isMute ? images.SpeakerOff : images.SpeakerOn}
                                draggable={false}
                            />
                        </div>
                        <img
                            alt=""
                            id="sky-bg" 
                            src={images.Sky}
                        />
                        <img 
                            alt=''
                            id="rainbow-bg" 
                            src={images.Rainbow}
                        />
                        <img 
                            alt=''
                            id="blank-bg" 
                            src={images.Background}
                        />
                        <img 
                            alt=''
                            id="ribbon" 
                            src={images.Ribbon}
                        />
                        <img 
                            alt=''
                            id="ribbon-text" 
                            src={images.RibbonText}
                        />
                        <img 
                            alt=''
                            className='star align-left'
                            src={images.Star}
                        />
                        <img 
                            alt=''
                            className='star align-right'
                            src={images.Star}
                        />
                        <div className='container-you-made-it' id='you-made-it'>
                        </div>
                        <div
                            className='grid-container'
                        >
                            <div className='grid-item'>
                            </div>
                            {/* {this.render30ItemsAchievement()} */}
                        </div>
                    </div>
                    <div className="container-audio">
                        <video
                            style={{ visibility: 'hidden'}}
                            ref={(domBgmCompleted) => { this.domBgmCompleted = domBgmCompleted }}
                            muted
                            autoPlay
                            playsInline
                            onEnded={this.handleOnEndAudioCompleted}
                        >
                            <source src={CongrasSound} type="audio/mpeg"/>
                        </video>
                        <video
                            style={{ visibility: 'hidden'}}
                            ref={(domBgmMain) => { this.domBgmMain = domBgmMain }}
                            muted
                            loop
                            playsInline
                        >
                            <source src={TeaTime} type="audio/mpeg"/>
                        </video>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Complete;