import React from 'react';
import ImgBubble from '../../../assets/images/day_six_to_ten/bubble.svg';
import ImgFlowers from '../../../assets/images/day_six_to_ten/flowers.svg';
import ImgLandBluePink from '../../../assets/images/day_six_to_ten/land-bluepink.svg';
import ImgStampsBg1 from '../../../assets/images/day_six_to_ten/stamps_bg_1.svg';
import ImgStampsBg2 from '../../../assets/images/day_six_to_ten/stamps_bg_2.svg';
import ImgStampsBg3 from '../../../assets/images/day_six_to_ten/stamps_bg_3.svg';
import ImgStampsBg4 from '../../../assets/images/day_six_to_ten/stamps_bg_4.svg';
import ImgStampsBg5 from '../../../assets/images/day_six_to_ten/stamps_bg_5.svg';
import ImgSunBurst1 from '../../../assets/images/day_six_to_ten/sunburst_1.svg';
import ImgSunBurst2 from '../../../assets/images/day_six_to_ten/sunburst_2.svg';
import ImgSunBurst3 from '../../../assets/images/day_six_to_ten/sunburst_3.svg';
import ImgSunBurst4 from '../../../assets/images/day_six_to_ten/sunburst_4.svg';
import ImgSunBurst5 from '../../../assets/images/day_six_to_ten/sunburst_5.svg';
import ImgStampsItem1 from '../../../assets/icons/day_six_to_ten/stamps_item_1.svg';  
import ImgStampsItem2 from '../../../assets/icons/day_six_to_ten/stamps_item_2.svg';  
import ImgStampsItem3 from '../../../assets/icons/day_six_to_ten/stamps_item_3.svg';  
import ImgStampsItem4 from '../../../assets/icons/day_six_to_ten/stamps_item_4.svg';        
import ImgStampsItem5 from '../../../assets/icons/day_six_to_ten/stamps_item_5.svg';  
import './DaySixToTen.scss';
/**
 * Class DaySixToTen
 */
class DaySixToTen extends React.Component {
    /**
     * Render background and image stamps
     * @return {object}
     */
    renderBackground = () => {
        const { indexStamps } = this.props;
        switch (indexStamps) {
            case 1:
                return {background: ImgStampsBg1, sunburst: ImgSunBurst1, item: ImgStampsItem1};
            case 2:
                return {background: ImgStampsBg2, sunburst: ImgSunBurst2, item: ImgStampsItem2};
            case 3:
                return {background: ImgStampsBg3, sunburst: ImgSunBurst3, item: ImgStampsItem3};
            case 4:
                return {background: ImgStampsBg4, sunburst: ImgSunBurst4, item: ImgStampsItem4}
            default:
                return {background: ImgStampsBg5, sunburst: ImgSunBurst5, item: ImgStampsItem5};
        }
    }

    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { indexStamps } = this.props;
        const { background, sunburst, item } = this.renderBackground();
        return (
            <React.Fragment>
                <div className="app-container-stamps container-day-six-ten">
                    <div id="container-stamp-reveal-6To10">
                        <img
                            alt=''
                            id='background'
                            src={background}
                        />
                        <img
                            alt=''
                            id='sunburst'
                            src={sunburst}
                        />
                        <img
                            alt=''
                            id='land'
                            src={ImgLandBluePink}
                        />
                        <img
                            alt=''
                            id='flowers'
                            src={ImgFlowers}
                        />
                        <img
                            alt=''
                            id='bubble'
                            src={ImgBubble}
                        />
                    </div>
                </div>
                <div className="app-container-item-stamps-tap container-item-stamps-tap">
                    <img
                        alt=''
                        className={`app-img-item-stamps img-item-stamps-six-ten-${indexStamps}`}
                        draggable={false}
                        src={item}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default DaySixToTen;