import SpeakerOn    from '../../../assets/icons/speaker_on.svg';
import SpeakerOff   from '../../../assets/icons/speaker_off.svg';
import Rainbow      from '../../../assets/images/10_completed/rainbow.png';
import Sky          from '../../../assets/images/10_completed/sky.png';
import Background   from '../../../assets/images/10_completed/blank_bg.png';
import Ribbon       from '../../../assets/images/10_completed/ribbon_top.png';
import RibbonText   from '../../../assets/images/10_completed/ribbon_text.png';
import Star         from '../../../assets/images/10_completed/star.png';
// import YouMadeIt    from '../../../assets/images/10_completed/YouMadeIt.png'

// // item achievement row 1
// import row1_1 from '../../../assets/images/10_completed/row_days/row1/1.svg';
// import row1_2 from '../../../assets/images/10_completed/row_days/row1/2.svg';
// import row1_3 from '../../../assets/images/10_completed/row_days/row1/3.svg';
// import row1_4 from '../../../assets/images/10_completed/row_days/row1/4.svg';
// import row1_5 from '../../../assets/images/10_completed/row_days/row1/5.svg';

// // item achievement row 2
// import row2_1 from '../../../assets/images/10_completed/row_days/row2/1.svg';
// import row2_2 from '../../../assets/images/10_completed/row_days/row2/2.svg';
// import row2_3 from '../../../assets/images/10_completed/row_days/row2/3.svg';
// import row2_4 from '../../../assets/images/10_completed/row_days/row2/4.svg';
// import row2_5 from '../../../assets/images/10_completed/row_days/row2/5.svg';

// // item achievement row 3
// import row3_1 from '../../../assets/images/10_completed/row_days/row3/1.svg';
// import row3_2 from '../../../assets/images/10_completed/row_days/row3/2.svg';
// import row3_3 from '../../../assets/images/10_completed/row_days/row3/3.svg';
// import row3_4 from '../../../assets/images/10_completed/row_days/row3/4.svg';
// import row3_5 from '../../../assets/images/10_completed/row_days/row3/5.svg';

// // item achievement row 4
// import row4_1 from '../../../assets/images/10_completed/row_days/row4/1.svg';
// import row4_2 from '../../../assets/images/10_completed/row_days/row4/2.svg';
// import row4_3 from '../../../assets/images/10_completed/row_days/row4/3.svg';
// import row4_4 from '../../../assets/images/10_completed/row_days/row4/4.svg';
// import row4_5 from '../../../assets/images/10_completed/row_days/row4/5.svg';

// // item achievement row 5
// import row5_1 from '../../../assets/images/10_completed/row_days/row5/1.svg';
// import row5_2 from '../../../assets/images/10_completed/row_days/row5/2.svg';
// import row5_3 from '../../../assets/images/10_completed/row_days/row5/3.svg';
// import row5_4 from '../../../assets/images/10_completed/row_days/row5/4.svg';
// import row5_5 from '../../../assets/images/10_completed/row_days/row5/5.svg';

// // item achievement row 6
// import row6_1 from '../../../assets/images/10_completed/row_days/row6/1.svg';
// import row6_2 from '../../../assets/images/10_completed/row_days/row6/2.svg';
// import row6_3 from '../../../assets/images/10_completed/row_days/row6/3.svg';
// import row6_4 from '../../../assets/images/10_completed/row_days/row6/4.svg';
// import row6_5 from '../../../assets/images/10_completed/row_days/row6/5.svg';

const images = {
    Rainbow,
    SpeakerOn,
    SpeakerOff,
    Sky,
    Background,
    Ribbon,
    RibbonText,
    Star,
    // YouMadeIt,
    // item achievement row 1
    // row1_1,
    // row1_2,
    // row1_3,
    // row1_4,
    // row1_5,
    // // item achievement row 2
    // row2_1,
    // row2_2,
    // row2_3,
    // row2_4,
    // row2_5,
    // // item achievement row 3
    // row3_1,
    // row3_2,
    // row3_3,
    // row3_4,
    // row3_5,
    // // item achievement row 4
    // row4_1,
    // row4_2,
    // row4_3,
    // row4_4,
    // row4_5,
    // // item achievement row 5
    // row5_1,
    // row5_2,
    // row5_3,
    // row5_4,
    // row5_5,
    // // item achievement row 5
    // row6_1,
    // row6_2,
    // row6_3,
    // row6_4,
    // row6_5,
}
export default images;