import React from "react";
import ImgStampsBg1 from "../../../assets/images/day_sixteen_to_twenty/stamps_bg_1.svg";
import ImgStampsDay1Wave1 from "../../../assets/images/day_sixteen_to_twenty/stamps_wave_day_1_1.svg";
import ImgStampsStart1 from "../../../assets/images/day_sixteen_to_twenty/stamps_star_1.svg";
import ImgStampsDay1Flower1 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_1.svg";
import ImgStampsDay1Flower2 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_2.svg";
import ImgStampsDay1Flower3 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_3.svg";
import ImgStampsFish1 from "../../../assets/images/day_sixteen_to_twenty/stamps_fish_1.svg";
import ImgStampsDay1Wave2 from "../../../assets/images/day_sixteen_to_twenty/stamps_wave_day_1_2.svg";
import ImgStampsItem1 from "../../../assets/icons/day_sixteen_to_twenty/stamps_item_1.svg";  
import ImgStampsBg2 from "../../../assets/images/day_sixteen_to_twenty/stamps_bg_2.svg";
import ImgStampsDay2Wave1 from "../../../assets/images/day_sixteen_to_twenty/stamps_wave_day_2_1.svg";
import ImgStampsStart2 from "../../../assets/images/day_sixteen_to_twenty/stamps_star_1.svg";
import ImgStampsDay2Flower1 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_1.svg";
import ImgStampsDay2Flower2 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_2.svg";
import ImgStampsDay2Flower3 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_3.svg";
import ImgStampsFish2 from "../../../assets/images/day_sixteen_to_twenty/stamps_fish_1.svg";
import ImgStampsDay2Wave2 from "../../../assets/images/day_sixteen_to_twenty/stamps_wave_day_2_2.svg";
import ImgStampsItem2 from "../../../assets/icons/day_sixteen_to_twenty/stamps_item_2.svg";  
import ImgStampsBg3 from "../../../assets/images/day_sixteen_to_twenty/stamps_bg_3.svg";
import ImgStampsDay3Wave1 from "../../../assets/images/day_sixteen_to_twenty/stamps_wave_day_3_1.svg";
import ImgStampsStart3 from "../../../assets/images/day_sixteen_to_twenty/stamps_star_1.svg";
import ImgStampsDay3Flower1 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_1.svg";
import ImgStampsDay3Flower2 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_2.svg";
import ImgStampsDay3Flower3 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_3.svg";
import ImgStampsFish3 from "../../../assets/images/day_sixteen_to_twenty/stamps_fish_1.svg";
import ImgStampsDay3Wave2 from "../../../assets/images/day_sixteen_to_twenty/stamps_wave_day_2_2.svg";
import ImgStampsItem3 from "../../../assets/icons/day_sixteen_to_twenty/stamps_item_3.svg";  
import ImgStampsBg4 from "../../../assets/images/day_sixteen_to_twenty/stamps_bg_4.svg";
import ImgStampsDay4Wave1 from "../../../assets/images/day_sixteen_to_twenty/stamps_wave_day_4_1.svg";
import ImgStampsStart4 from "../../../assets/images/day_sixteen_to_twenty/stamps_star_1.svg";
import ImgStampsDay4Flower1 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_1.svg";
import ImgStampsDay4Flower2 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_2.svg";
import ImgStampsDay4Flower3 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_3.svg";
import ImgStampsFish4 from "../../../assets/images/day_sixteen_to_twenty/stamps_fish_1.svg";
import ImgStampsDay4Wave2 from "../../../assets/images/day_sixteen_to_twenty/stamps_wave_day_2_2.svg";
import ImgStampsItem4 from "../../../assets/icons/day_sixteen_to_twenty/stamps_item_4.svg";  
import ImgStampsBg5 from "../../../assets/images/day_sixteen_to_twenty/stamps_bg_5.svg";
import ImgStampsDay5Wave1 from "../../../assets/images/day_sixteen_to_twenty/stamps_wave_day_5_1.svg";
import ImgStampsStart5 from "../../../assets/images/day_sixteen_to_twenty/stamps_star_1.svg";
import ImgStampsDay5Flower1 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_1.svg";
import ImgStampsDay5Flower2 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_2.svg";
import ImgStampsDay5Flower3 from "../../../assets/images/day_sixteen_to_twenty/stamps_flower_day_1_3.svg";
import ImgStampsFish5 from "../../../assets/images/day_sixteen_to_twenty/stamps_fish_1.svg";
import ImgStampsDay5Wave2 from "../../../assets/images/day_sixteen_to_twenty/stamps_wave_day_2_2.svg";
import ImgStampsItem5 from "../../../assets/icons/day_sixteen_to_twenty/stamps_item_5.svg";
import "./DaySixteenToTwenty.scss";
/**
 * Class DaySixteenToTwenty 
 */
class DaySixteenToTwenty extends React.Component {
    /**
     * Render background and image stamps
     * @return {object}
     */
     renderBackground = () => {
        const { indexStamps } = this.props;
        switch (indexStamps) {
            case 1:
                return { background: ImgStampsBg1, wave1: ImgStampsDay1Wave1, star: ImgStampsStart1, flower1: ImgStampsDay1Flower1, flower2: ImgStampsDay1Flower2, 
                    flower3: ImgStampsDay1Flower3, fish: ImgStampsFish1, wave2: ImgStampsDay1Wave2, item: ImgStampsItem1 }
            case 2:
                return { background: ImgStampsBg2, wave1: ImgStampsDay2Wave1, star: ImgStampsStart2, flower1: ImgStampsDay2Flower1, flower2: ImgStampsDay2Flower2, 
                    flower3: ImgStampsDay2Flower3, fish: ImgStampsFish2, wave2: ImgStampsDay2Wave2, item: ImgStampsItem2 }
            case 3:
                return { background: ImgStampsBg3, wave1: ImgStampsDay3Wave1, star: ImgStampsStart3, flower1: ImgStampsDay3Flower1, flower2: ImgStampsDay3Flower2, 
                    flower3: ImgStampsDay3Flower3, fish: ImgStampsFish3, wave2: ImgStampsDay3Wave2, item: ImgStampsItem3 }
            case 4:
                return { background: ImgStampsBg4, wave1: ImgStampsDay4Wave1, star: ImgStampsStart4, flower1: ImgStampsDay4Flower1, flower2: ImgStampsDay4Flower2, 
                    flower3: ImgStampsDay4Flower3, fish: ImgStampsFish4, wave2: ImgStampsDay4Wave2, item: ImgStampsItem4 }
            default:
                return { background: ImgStampsBg5, wave1: ImgStampsDay5Wave1, star: ImgStampsStart5, flower1: ImgStampsDay5Flower1, flower2: ImgStampsDay5Flower2, 
                    flower3: ImgStampsDay5Flower3, fish: ImgStampsFish5, wave2: ImgStampsDay5Wave2, item: ImgStampsItem5 }
        }
    }

    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { indexStamps }= this.props;
        const { background, wave1, star, flower1, flower2, flower3, fish, wave2, item } = this.renderBackground();
        return (
            <React.Fragment>
                <div
                    className="app-container-stamps container-day-sixteen-twenty"
                    style={{
                        backgroundImage: `url(${background})`
                    }}
                >
                    <div className={`img-effect wave1 stamps-day-wave1 stamps-day-${indexStamps}-wave1`} style={{backgroundImage: `url(${wave1})`}}></div>
                    <div className="img-effect star" style={{backgroundImage: `url(${star})`}}></div>
                    <div className="img-effect flower1" style={{backgroundImage: `url(${flower1})`}}></div>
                    <div className="img-effect flower2" style={{backgroundImage: `url(${flower2})`}}></div>
                    <div className="img-effect flower3" style={{backgroundImage: `url(${flower3})`}}></div>
                    <div className="img-effect fish" style={{backgroundImage: `url(${fish})`}}></div>
                    <div className={`img-effect wave2 stamps-day-${indexStamps}-wave2`} style={{backgroundImage: `url(${wave2})`}}></div>
                </div>
                <div className="app-container-item-stamps-tap container-item-stamps-tap">
                    <img
                        alt=""
                        src={item}
                        draggable={false}
                        className={`app-img-item-stamps img-item-stamps-sixteen-twenty-${indexStamps}`}
                    />
                 </div>
            </React.Fragment>
        )
    }
}

export default DaySixteenToTwenty;