import React from 'react';
import imgClose from '../../../assets/icons/close.png';
import imgEarth from '../../../assets/images/earth-error.png';
import './ErrorModal.scss';
/**
 * Class ErrorModal
 */
class ErrorModal extends React.Component {
    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { isShow, onClose, kindError } = this.props;
        return (
            <React.Fragment>
                <div className={`modal-error ${isShow ? "" : "app-hidden-modal"}`}>
                    <div className="board">
                        <button className='btn-hide' onClick={onClose}>
                            <img src={imgClose} alt="" />
                        </button>
                        <div className='bg-modal'>
                            <img src={imgEarth} alt='' className='earth-error' />
                            <br />
                            <div>
                                {/* unallow click 1 times in 1 hour */}
                                <span style={{'display' : (kindError === 1) ? 'block' : 'none'}} className='content'>
                                    連続では<br />スタンプが押せないよ。
                                </span>
                                {/* unallow click 3 times a day */}
                                <span style={{'display' : (kindError === 2) ? 'block' : 'none'}} className='content'>
                                    1日3回以上では<br />スタンプが押せないよ。
                                </span>
                                {/* no internet */}
                                <span style={{'display' : (kindError === 3) ? 'block' : 'none'}} className='content'>
                                    インターネットの<br />接続がないよ。
                                </span>
                                {/* err exception */}
                                <span style={{'display' : (kindError === 4) ? 'block' : 'none'}} className='content'>
                                    予期しない操作により、<br />エラーが起きました。
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ErrorModal;