import { Routes as Router, Route } from 'react-router-dom';
import Home from '../components/home/Home';
function Routes() {
    return (
        <Router>
            <Route path="/*" element={<Home />} />
        </Router>
    )
}

export default Routes;
