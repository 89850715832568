import React from 'react';
import imgClose from '../../../assets/icons/close.png';
import img1 from '../../../assets/images/modal_product/bottel.png';
import img2 from '../../../assets/images/modal_product/dishwashpad.png';
import './ProductModal.scss';
/**
 * Class ProductModal
 */
class ProductModal extends React.Component {
    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { isShow, isShowSoap, onClose } = this.props;
        return (
            <React.Fragment>
                <div className={`modal-product ${isShow ? "" : "app-hidden-modal"}`}>
                    <div className="board">
                        <button className='btn-hide' onClick={onClose}><img src={imgClose} alt="" /></button>
                        <div className={`bg-modal ${isShowSoap ? "bg-modal-1" : "bg-modal-2"}`}>
                            <div className='content-img'>
                                <img src={isShowSoap ? img1 : img2} alt=""></img>
                            </div>
                            <div className="content" id="style-scroll-product">
                                <div className='content-text'>
                                    <span> {isShowSoap ?
                                        <React.Fragment>
                                            レストランで使った油や鳥がつついて食べれなくなったオレンジ、お米を作る時に出る米ぬかなど、本当なら捨てられるはずのものを原料にして作った食器用石けん。容器もリサイクルで
                                            <br />
                                            作ったアルミを使っているよ。石けんは合成洗剤より地球に良いから、川や海に流れてしまっても、分解されるよ。
                                        </React.Fragment>
                                        : "洋服を作るときに出る布の切れはしは、本来捨てられてしまうけれど、その切れはしをリサイクル糸にして作ったふきん。プラスチックのスポンジは、マイクロプラスチックが出て、川や海に流れていってしまうけど、このふきんはマイクロプラスチックが出ないから、川や海を汚さないよ。"
                                    }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ProductModal;