import React from "react";
import ImgStampsBg1 from "../../../assets/images/day_twenty_six_to_thirty/stamps_bg_1.svg";
import ImgStampsCloud1 from "../../../assets/images/day_twenty_six_to_thirty/stamps_cloud.svg";
import ImgStampsStar1 from "../../../assets/images//day_twenty_six_to_thirty/stamps_star.svg";
import ImgStampsFish1 from "../../../assets/images/day_twenty_six_to_thirty/stamps_fish.svg";
import ImgStampsRainbow1 from "../../../assets/images/day_twenty_six_to_thirty/stamps_rainbow.svg";
import ImgStampsItem1 from "../../../assets/icons/day_twenty_six_to_thirty/stamps_item_1.svg"; 
import ImgStampsBg2 from "../../../assets/images/day_twenty_six_to_thirty/stamps_bg_2.svg";
import ImgStampsCloud2 from "../../../assets/images/day_twenty_six_to_thirty/stamps_cloud.svg";
import ImgStampsStar2 from "../../../assets/images//day_twenty_six_to_thirty/stamps_star.svg";
import ImgStampsFish2 from "../../../assets/images/day_twenty_six_to_thirty/stamps_fish.svg";
import ImgStampsRainbow2 from "../../../assets/images/day_twenty_six_to_thirty/stamps_rainbow.svg";
import ImgStampsItem2 from "../../../assets/icons/day_twenty_six_to_thirty/stamps_item_2.svg";  
import ImgStampsBg3 from "../../../assets/images/day_twenty_six_to_thirty/stamps_bg_3.svg";
import ImgStampsCloud3 from "../../../assets/images/day_twenty_six_to_thirty/stamps_cloud.svg";
import ImgStampsStar3 from "../../../assets/images//day_twenty_six_to_thirty/stamps_star.svg";
import ImgStampsFish3 from "../../../assets/images/day_twenty_six_to_thirty/stamps_fish.svg";
import ImgStampsRainbow3 from "../../../assets/images/day_twenty_six_to_thirty/stamps_rainbow.svg";
import ImgStampsItem3 from "../../../assets/icons/day_twenty_six_to_thirty/stamps_item_3.svg";  
import ImgStampsBg4 from "../../../assets/images/day_twenty_six_to_thirty/stamps_bg_4.svg";
import ImgStampsCloud4 from "../../../assets/images/day_twenty_six_to_thirty/stamps_cloud.svg";
import ImgStampsStar4 from "../../../assets/images//day_twenty_six_to_thirty/stamps_star.svg";
import ImgStampsFish4 from "../../../assets/images/day_twenty_six_to_thirty/stamps_fish.svg";
import ImgStampsRainbow4 from "../../../assets/images/day_twenty_six_to_thirty/stamps_rainbow.svg";
import ImgStampsItem4 from "../../../assets/icons/day_twenty_six_to_thirty/stamps_item_4.svg";  
import ImgStampsBg5 from "../../../assets/images/day_twenty_six_to_thirty/stamps_bg_5.svg";
import ImgStampsCloud5 from "../../../assets/images/day_twenty_six_to_thirty/stamps_cloud.svg";
import ImgStampsStar5 from "../../../assets/images//day_twenty_six_to_thirty/stamps_star.svg";
import ImgStampsFish5 from "../../../assets/images/day_twenty_six_to_thirty/stamps_fish.svg";
import ImgStampsRainbow5 from "../../../assets/images/day_twenty_six_to_thirty/stamps_rainbow.svg";
import ImgStampsItem5 from "../../../assets/icons/day_twenty_six_to_thirty/stamps_item_5.svg";  
import "./DayTwentySixToThirty.scss";
/**
 * Class DayTwentySixToThirty 
 */
class DayTwentySixToThirty extends React.Component {
    /**
     * Render background and image stamps
     * @return {object}
     */
     renderBackground = () => {
        const { indexStamps } = this.props;
        switch (indexStamps) {
            case 1:
                return { background: ImgStampsBg1, cloud: ImgStampsCloud1, star: ImgStampsStar1, fish: ImgStampsFish1, rainbow: ImgStampsRainbow1, item: ImgStampsItem1 };
            case 2:
                return { background: ImgStampsBg2, cloud: ImgStampsCloud2, star: ImgStampsStar2, fish: ImgStampsFish2, rainbow: ImgStampsRainbow2, item: ImgStampsItem2 };
            case 3:
                return { background: ImgStampsBg3, cloud: ImgStampsCloud3, star: ImgStampsStar3, fish: ImgStampsFish3, rainbow: ImgStampsRainbow3, item: ImgStampsItem3 };
            case 4:
                return { background: ImgStampsBg4, cloud: ImgStampsCloud4, star: ImgStampsStar4, fish: ImgStampsFish4, rainbow: ImgStampsRainbow4, item: ImgStampsItem4 };
            default:
                return { background: ImgStampsBg5, cloud: ImgStampsCloud5, star: ImgStampsStar5, fish: ImgStampsFish5, rainbow: ImgStampsRainbow5, item: ImgStampsItem5 };
        }
    }

    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { indexStamps }= this.props;
        const { background, cloud, star, fish, rainbow, item } = this.renderBackground();
        return (
            <React.Fragment>
                <div
                    className="app-container-stamps container-day-twenty-six-thirty"
                    style={{
                        backgroundImage: `url(${background})`
                    }}
                >
                    <div className="img-effect cloud" style={{backgroundImage: `url(${cloud})`}}></div>
                    <div className="img-effect star" style={{backgroundImage: `url(${star})`}}></div>
                    <div className="img-effect fish" style={{backgroundImage: `url(${fish})`}}></div>
                    <div className="img-effect rainbow" style={{backgroundImage: `url(${rainbow})`}}></div>
                </div>
                <div className="app-container-item-stamps-tap container-item-stamps-tap">
                    <img
                        alt=""
                        src={item}
                        draggable={false}
                        className={`app-img-item-stamps img-item-stamps-twenty-six-thirty-${indexStamps}`}
                    />
                 </div>
            </React.Fragment>
        )
    }
}

export default DayTwentySixToThirty;