import React from 'react';
import ImgEarth from '../../../assets/images/start/earth-login.png';
import ImgSpeakerOn from '../../../assets/icons/speaker_on.png';
import ImgSpeakerOff from '../../../assets/icons/speaker_off.png';
import ImgHand from '../../../assets/images/start/hand.png';
import './Start.scss';
/**
 * Class Splash (0-Splash screen)
 */
class Start extends React.Component {
    /**
     * Click スタート button
     * @param {event} e
     * @return {void}
     */
    handleClickStart = async (e) => {
        const { changeState, handleSfxButton } = this.props;
        const dataRound = {...this.props.dataRound};
        await handleSfxButton(2, dataRound.isMute);
        if(!navigator.onLine) return changeState({isShowErrConnectModal: true});
        dataRound.flow += 1;
        changeState({dataRound});
    }

     /**
     * Toogle status audio
     * @return {void}
     */
    handleClicktoggleAudio = (e) => {
        const { handleClicktoggleAudio } = this.props;
        handleClicktoggleAudio(
            () => {},
            () => {}
        );
    }
   
    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { isMute } = this.props.dataRound;
        return (
            <React.Fragment>
                <div className="container-start">
                    <div className='bg-1'></div>
                    <div className='bg-2'></div>
                    <div className='bg-3'> </div>
                    <div className='bg-4'>
                    </div>
                    <div className='bg-5'>
                        <div className='imgTitle'>
                        </div>
                        <div className='bg-cloud'></div>
                        <div className='div-btn-start'>
                            <button className='btnStart' onClick={this.handleClickStart}>
                            </button>
                        </div>
                        <img src={ImgHand} alt="" className='bg-hand'/>
                        <img src={ImgEarth} alt="" className='imgEarth'/>
                        <button className='flg-btn-sound btn-sound' 
                                    onClick={this.handleClicktoggleAudio}>
                            <img src={!isMute ? ImgSpeakerOn : ImgSpeakerOff} alt=""></img>
                        </button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Start;