import React from 'react';
import { masterLayout } from '../../hocs/MasterLayout/MasterLayout';
import * as Constant from '../../_config/Constants';
import Splash from './splash/Splash';
import Start from './start/Start';
import HomeDay from '../../_share/home_day/HomeDay';
import Complete from './completed/Completed';
// import Earth30th from './earth30th/Earth30th';
import Thanks from './12_30th_thanks/12_30th_Thanks';
import ErrorModal from '../../_share/home_day/error_modal/ErrorModal';
import { checkCookie, initCookie, getCookie, updateCookie } from '../../utils/CommonUtil';
import AudioAlon from '../../assets/sounds/Alon Peretz - Blue Toy Train.mp3';
import BtnSoundSfx from '../../assets/sounds/button/Soundkrampf - Tractor - Back Lights, Turn Off .aac';
import BtnPrimarySfx from '../../assets/sounds/button/Omnibit Sound - Cartoon Mania - Hollow Bonk, Squeaky Hit.mp3';
import AudioNiSoundNoti from '../../assets/sounds/Ni Sound - Cute Interface -  Double Bubble Pop Notification.aac';
import AudioNiSoundNextFlow from '../../assets/sounds/Ni Sound - Heartwarming Transitions - Enchanted Harp Phrase, Positive, G Major .aac';
import ProductModal from '../../_share/home_day/product_modal/ProductModal';
import KnowledgeModal from './knowledge_modal/KnowledgeModal';
import KnowledgeListModal from './knowledge_list_modal/KnowledgeListModal';
import ReviewKnowledge from '../../_share/home_day/review_knowledge/ReviewKnowledge';
import VideoEarth from '../../assets/videos/30thEarth.mp4';
import './Home.scss';
/**
 * Class Home
 */
class Home extends React.Component {
    /**
     * Constructor
     * @param {object} props
     * @return {void}
     */
    constructor(props) {
        super(props);
        if(!checkCookie(Constant.COOKIE_NAME_ROUND)) initCookie();
        const dataRound = JSON.parse(getCookie(Constant.COOKIE_NAME_ROUND));
        // auto next screen
        if([5, 7, 9, 11, 13, 15].includes(dataRound.flow) && dataRound.roundCr === 5) {
            dataRound.flow += 1;
            dataRound.roundCr = -1;
        }
        this.state = {
            dateInit: new Date(),
            isClickScreen: false,
            indexStamps: -1,
            dataRound: {...dataRound, isMute: true},
            isShowErr1Times1HourModal: false,
            isShowErr3Times1DayModal: false,
            isShowErrConnectModal: false,
            isShowKnowledgeListModal: false, // 豆知識
            isShowKnowledgeModal: [6, 8, 10, 12, 14, 16].includes(dataRound.flow),
            groupRoundName: "",
            isShowSoapModal: false, // 食器用石けん
            isShowDishTowelModal: false, // 食器用ふきん
            isShowCookieModal: false, // 注意事項
        }
        this.domAudio = null;
        this.isAudioPlaying = false;
        this.domBtnSoundSfx = null;
        this.domBtnPrimarySfx = null;
        this.domSfxModalKnowledge = null;
        this.domAudioNoti = null;
        this.domAudioNiSoundNextFlow = null;
        this.domVideo = null;
        this.timerNextScreenVideo = undefined;
    }

    /**
     * Lifecycle
     * Runs after the component output has been rendered to the DOM
     * @return {void}
     */
    componentDidMount() {
        const { dataRound } = this.state;
        if(dataRound.flow < 5 && dataRound.flow > 1) {
            this.domAudio.muted = dataRound.isMute;
            // this.domAudio.currentTime = 0;
        }
        document.addEventListener("mousedown", this.handleMouseDown);
    }

    /**
     * Lifecycle
     * Run after the component update state or props
     * @param {object} prevProps Previous props
     * @param {object} prevState Previous state
     * @return {void}
     */
    componentDidUpdate(prevProps, prevState) {
        const { isShowErrConnectModal, dataRound, indexStamps, isShowKnowledgeModal, isShowKnowledgeListModal, groupRoundName } = this.state;
        const { flow } = dataRound;
        if(flow === 2 && (prevState.dataRound.flow < 2 || prevState.dataRound.flow > 4)) {
            // this.domAudio.currentTime = 0;
            this.domAudio.play();
            this.setState({dateInit: new Date()})
        }

        if((flow < 2 || flow > 4)) {
            this.domAudio.pause();
            this.domAudio.currentTime = 0;
            this.isAudioPlaying = false;
        }
        if(prevState.dataRound.flow !== 18 && flow === 18){
            this.domAudio.pause();
            this.domAudio.currentTime = 0;
            if(document.getElementById('video30thEarth')){
                document.getElementById('video30thEarth').addEventListener('ended', () => {
                    if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
                    const dataRound = {...this.state.dataRound};
                    this.setState({dataRound: {...dataRound, flow: dataRound.flow + 1}});
                }, false);
            }
            this.domVideo.muted = dataRound.isMute;
            this.domVideo.play();
        }
        if(indexStamps !== -1 && indexStamps !== prevState.indexStamps) {
            this.domAudioNoti.pause();
            this.domAudioNoti.currentTime = 0;

            this.domAudioNiSoundNextFlow.pause();
            this.domAudioNiSoundNextFlow.currentTime = 0;
        }

        if(flow !== prevState.dataRound.flow) {
            if(flow === 17){
                this.timerNextScreenVideo = setTimeout(() => {
                    if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
                    this.setState({dataRound: {...dataRound, flow: dataRound.flow + 1}});
                }, 14000);  // delay 12s + 2s Tada sound to auto move next screen
            } else if(flow > 17){
                clearTimeout(this.timerNextScreenVideo);
            }
        }
        if(!isShowErrConnectModal && prevState.isShowErrConnectModal) {
            if(flow === 17) {
                this.timerNextScreenVideo = setTimeout(() => {
                    if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
                    this.setState({dataRound: {...dataRound, flow: dataRound.flow + 1}});
                }, Constant.TIMER_RECHECK_ONLINE);
            } else if(flow > 17){
                clearTimeout(this.timerNextScreenVideo);
            }
        }

        if(!isShowErrConnectModal && isShowKnowledgeModal) {
            // Sound of pop-up appearance:
            // Gamemaster Audio - Magic Spells - Delight Ding, Bubbly, Level Up
            if( dataRound.roundCr !== 5 && groupRoundName !== "" ) {
                // Sound of pop-up appearance:
                // Ni Sound - Cute Interface -  Double Bubble Pop Notification
                this.domAudioNoti.muted = dataRound.isMute;
                this.domAudioNoti.volume = 1.0;
                this.domAudioNoti.play();
            }
        }
        if(!isShowErrConnectModal && isShowKnowledgeListModal) {
            // this.domAudioNoti.pause();
            this.domAudioNoti.muted = dataRound.isMute;
            this.domAudioNoti.volume = 1.0;

            // this.domAudioNoti.currentTime = 0;
            this.domAudioNoti.play();
        }
        // When you close the Knowledge List modal, sound effects as a level up appears, and return to the Day Home Screen (= close the Knowledge Pop-up, it turns to the next day screen)
        // Ni Sound - Heartwarming Transitions - Enchanted Harp Phrase, Positive, G Major 
        if(!isShowErrConnectModal && [7, 9, 11, 13, 15].includes(dataRound.flow) && dataRound.flow !== prevState.dataRound.flow) {
            // this.domAudioNiSoundNextFlow.pause();
            this.domAudioNiSoundNextFlow.muted = dataRound.isMute;
            // this.domAudioNiSoundNextFlow.currentTime = 0;
            this.domAudioNiSoundNextFlow.volume = 1.0;

            this.domAudioNiSoundNextFlow.play();
        }

        // update cookie
        const dataRoundCookie = {...this.state.dataRound};
        delete dataRoundCookie.isMute;
        updateCookie(Constant.COOKIE_NAME_ROUND, JSON.stringify(dataRoundCookie));
        
    }

    /**
     * Lifecycle
     * Component is being removed from the DOM
     * @return {void}
     */
    componentWillUnmount() { 
        document.removeEventListener("mousedown", this.handleMouseDown);
        clearTimeout(this.timerNextScreenVideo);
    }

    /**
     * Mouse down
     * @param {event} e
     * @return {void}
     */
    handleMouseDown = (e) => {
        if(e.target.closest(".flg-btn-sound")) return;
        const dataRound = {...this.state.dataRound};
        if((dataRound.flow === 17 && e.target.id !== '10-completed-speaker') || dataRound.flow === 18) {
            if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
            this.setState({dataRound: {...dataRound, flow: dataRound.flow + 1}});
        }
    }

    /**
     * Click close button
     * @param {e} event
     * @return {void}
     */
    handleCloseErrModal = () => {
        this.handleSfxButton(2, this.state.dataRound.isMute)
        if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
        this.setState({isShowErr3Times1DayModal: false, isShowErr1Times1HourModal: false});
    }

    /**
     * Click close button
     * @param {e} event
     * @return {void}
     */
    handleCloseErrConnectModal = () => {
        this.handleSfxButton(2, this.state.dataRound.isMute)
        this.setState({isShowErrConnectModal: false});
    }

    /**
     * Play audio when click primary button (Start/Close/Towel & Soap Info/Knowledge List/Restart)
     * @param {number} kind 
     * @param {boolean} isMute
     * @return {void} 
     */
    handleSfxButton = (kind, isMute) => {
        this.domBtnPrimarySfx.pause();
        // this.domBtnPrimarySfx.currentTime = 0;

        this.domBtnSoundSfx.pause();
        // this.domBtnSoundSfx.currentTime = 0;
        if (kind === 1) {
            this.domBtnSoundSfx.muted = false;
            this.domBtnSoundSfx.volume = 1.0;
            // this.domBtnPrimarySfx.currentTime = 0;
            this.domBtnSoundSfx.play();
        } else {
            this.domBtnPrimarySfx.muted = isMute;
            this.domBtnPrimarySfx.volume = 1.0;
            // this.domBtnPrimarySfx.currentTime = 0;
            this.domBtnPrimarySfx.play();
            // return await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         resolve(true);
            //     }, 300);
            // })
        }
    }

    /**
     * Toggle audio
     * @param {function} cbBefore 
     * @param {function} cbAfter 
     * @return {void}
     */
    handleClicktoggleAudio = async (cbBefore, cbAfter) => {
        if(!this.state.isClickScreen) this.playAudio();
        const dataRound = {...this.state.dataRound};
        const flgIsMute = !dataRound.isMute;
        this.handleSfxButton(1, flgIsMute);
        if(!navigator.onLine) {
            cbAfter(dataRound.isMute);
            this.setState({isShowErrConnectModal: true});
            return;
        }
        dataRound.isMute = !dataRound.isMute;
        this.setState({dataRound});
        if(flgIsMute) {
            cbAfter(flgIsMute);
            this.domAudio.muted = flgIsMute;
            return;
        }
        cbBefore();
        setTimeout(async () => {
            cbAfter(flgIsMute);
            // audio
            this.domAudio.muted = flgIsMute;
        }, 500);
    }

    /**
     * Change state
     * @param {object} state 
     */
    changeState = (state) => {
        this.setState(state);
    }

    /**
     * Render layout by flow
     * @return {jsx}
     */
    renderLayoutByFlow = () => {
        const { indexStamps, isShowErr3Times1DayModal, isShowErr1Times1HourModal, isShowErrConnectModal, isShowKnowledgeModal, groupRoundName } = this.state;
        const dataRound = {...this.state.dataRound}
        const objProps = {
            isShow: true,
            indexStamps,
            dataRound,
            isShowErr1Times1HourModal,
            isShowErr3Times1DayModal,
            isShowErrConnectModal,
            isShowKnowledgeModal,
            groupRoundName,
            handleClickTrivia: this.handleClickTrivia, 
            handleClickSoap: this.handleClickSoap, 
            handClickDishTowel: this.handClickDishTowel, 
            handleClickCookie: this.handleClickCookie,
            handleSfxButton: this.handleSfxButton,
            handleClicktoggleAudio: this.handleClicktoggleAudio,
            changeState: this.changeState,
        }
        switch (dataRound.flow) {
            case 1: {
                return <Splash {...objProps} />
            }
            case 2: {
                return <Start {...objProps} />
            }
            case 3: // overview
            case 4: // cookie
            case 5: // stamps
            case 6: // knowledge popup
                return <HomeDay {...Constant.DATA_HOME_DAY.DAY_ONE_TO_FIVE} {...objProps} />;
            case 7: // stamps
            case 8: // knowledge poup
                return <HomeDay {...Constant.DATA_HOME_DAY.DAY_SIX_TO_TEN} {...objProps} />;
            case 9: // stamps
            case 10: // knowledge
            return <HomeDay {...Constant.DATA_HOME_DAY.DAY_ELEVENT_TO_FIFTEEN} {...objProps} />;
            case 11: // stamps
            case 12: // knowledge
            return <HomeDay {...Constant.DATA_HOME_DAY.DAY_SIXTEEN_TO_TWENTY} {...objProps} />;
            case 13: // stamps
            case 14: // knowledge
            return <HomeDay {...Constant.DATA_HOME_DAY.DAY_TWENTY_ONE_TO_TWENTY_FIVE} {...objProps} />;
            case 15: // stamps
            case 16: // knowledge
            return <HomeDay {...Constant.DATA_HOME_DAY.DAY_TWENTY_SIX_TO_THIRTY} {...objProps} />;
            case 17: 
                return <Complete {...objProps} />;
            // case 18:
            //     return <Earth30th {...objProps} />;
            case 19:
                return <Thanks {...objProps} />;
            default:
                break;
        }
    }

    /**
     * Play audio
     * @return {void}
     */
    playAudio = async () => {
        const { isClickScreen, dataRound } = this.state;
        // const diffTime = new Date().getTime() - dateInit.getTime();
        if(dataRound.flow === 2) {
            // const duration = Math.floor(this.domAudio.duration * 1000);
            // this.domAudio.currentTime = (diffTime % duration)/1000;
            this.domAudio.muted = dataRound.isMute;
            await this.domAudio.play();
        }
        if(!isClickScreen) this.setState({isClickScreen: true});
    }

    /**
     * Handle click close button in REVIEW KNOWLEDGE POP-UP (ON THANKS SCREEN)
     * @return {void}
     */
    nextFlow = async () => {
        const dataRound = {...this.state.dataRound};
        this.handleSfxButton(2, dataRound.isMute);
        if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
        dataRound.flow += 1;
        this.setState({dataRound});
    }

    /**
     * Click メグループ石けんの説明 button then show modal 食器用石けん
     * @param {e} event
     * @return {void}
     */
    handleClickSoap = async (e) => {
        const dataRound = {...this.state.dataRound};
        this.handleSfxButton(2, dataRound.isMute);
        if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
        this.setState({isShowSoapModal: true});
    }

    /**
     * Close soap modal
     * @param {event} e
     * @return {void} 
     */
    handleCloseSoap = async (e) => {
        const dataRound = {...this.state.dataRound};
        this.handleSfxButton(2, dataRound.isMute);
        if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
        this.setState({isShowSoapModal: false});
    }

    /**
     * Click メグループふきんの説明 button then show modal 食器用ふきん
     *  @param {e} event
     * @return {void}
     */
    handClickDishTowel = async (e) => {
        const dataRound = {...this.state.dataRound};
        this.handleSfxButton(2, dataRound.isMute);
        if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
        this.setState({isShowDishTowelModal: true});
    }

    /**
     * Close dish towel modal
     * @param {event} e
     * @return {void} 
     */
    handleCloseDishTowel = async (e) => {
        const dataRound = {...this.state.dataRound};
        this.handleSfxButton(2, dataRound.isMute);
        if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
        this.setState({isShowDishTowelModal: false});
    }

    /**
     * Click 豆知識
     * @param {event} e
     * @return {void}
     */
    handleClickTrivia = async (e) => {
        const dataRound = {...this.state.dataRound};
        this.handleSfxButton(2, dataRound.isMute);
        if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
        this.setState({isShowKnowledgeModal: false, groupRoundName: "", isShowKnowledgeListModal: true});
    }

    /**
     * Click item trivia
     * @return {void}
     */
    handleClickItemTrivia = (indexGroupStamps) => {
        if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
        this.setState({isShowKnowledgeModal: true, groupRoundName: `groupRound${indexGroupStamps}`, isShowKnowledgeListModal: false});
    }

    /**
     * Close trivia modal
     * @return {void}
     */
    handleCloseTrivia = async () => {
        const dataRound = {...this.state.dataRound};
        this.handleSfxButton(2, dataRound.isMute);
        if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
        if(dataRound.roundCr === -1) {
            // next flow (next home day)
            dataRound.roundCr = 0;
            dataRound.flow += 1;
        }
        this.setState({dataRound, isShowKnowledgeModal: false, groupRoundName: "", isShowKnowledgeListModal: false});
    }

    /**
     * Close knowledge
     * @param {event} e
     * @return {void}
     */
    handleCloseKnowledge = async (e) => {
        const dataRound = {...this.state.dataRound};
        this.handleSfxButton(2, dataRound.isMute);
        if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
        if(dataRound.roundCr === -1) {
            // next flow (next home day)
            dataRound.roundCr = 0;
            dataRound.flow += 1;
        }
        this.setState({dataRound, isShowKnowledgeModal: false, groupRoundName: "", isShowKnowledgeListModal: false});
    }

    /**
     * Click 注意事項
     * @param {event} e
     * @return {void} 
     */
    handleClickCookie = (e) => {
        if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
        this.setState({isShowCookieModal: true});
    }

    /**
     * Close cookie modal
     * @param {event} e 
     * @return {void}
     */
    handleCloseCookie = async (e) => {
        const dataRound = {...this.state.dataRound};
        this.handleSfxButton(2, dataRound.isMute);
        if(!navigator.onLine) return this.setState({isShowErrConnectModal: true});
        this.setState({isShowCookieModal: false});
    } 

    /**
     * Render all popup
     * GUIDE AND COOKIE ACCEPT POP-UP
     * PRODUCTS POP-UP
     * KNOWLEDGE POP-UP
     * REVIEW KNOWLEDGE POP-UP (ON THANKS SCREEN)
     * @return {jsx}
     */
    renderPopup = () => {
        const { isShowErr3Times1DayModal, isShowErr1Times1HourModal, isShowErrConnectModal, isShowSoapModal, isShowDishTowelModal, isShowKnowledgeListModal, isShowKnowledgeModal, groupRoundName, isShowCookieModal, dataRound } = this.state;
        const kindError = (isShowErrConnectModal) ? 3 : (isShowErr1Times1HourModal) ? 1 : (isShowErr3Times1DayModal) ? 2 : null;
        const isHasError = (isShowErr3Times1DayModal || isShowErr1Times1HourModal || isShowErrConnectModal) ? true : false;
        return (
            <React.Fragment>
                {/* GUIDE AND COOKIE ACCEPT POP-UP */}
                {[1, 2, 3].map((item) => {
                    return <ReviewKnowledge key={item} isShow={!isHasError && (item === 3 ? isShowCookieModal : (item === 1 ? dataRound.flow === 3 : dataRound.flow === 4))} isShowOverview={item === 1} onClose={item === 3 ? this.handleCloseCookie : this.nextFlow } />
                })}
                {/* PRODUCTS POP-UP */}
                {[1, 2].map((item) => {
                    return <ProductModal key={item} isShow={!isHasError && (item === 1 ? isShowSoapModal : isShowDishTowelModal)} isShowSoap={item === 1} onClose={item === 1 ? this.handleCloseSoap : this.handleCloseDishTowel} />
                })}
                {/* KNOWLEDGE POP-UP */}
                {(!isHasError && isShowKnowledgeListModal) && <KnowledgeListModal isShow={true} dataRound={dataRound} onClickItem={this.handleClickItemTrivia} onClose={this.handleCloseTrivia} />}
                {/* REVIEW KNOWLEDGE POP-UP (ON THANKS SCREEN) */}
                {
                    [6, 8, 10, 12, 14, 16].map((item, index) => {
                        return <KnowledgeModal 
                        key={item}
                        isShow={!isHasError && isShowKnowledgeModal && ((dataRound.flow === item && !groupRoundName) || (groupRoundName === "groupRound" + (index +1)))} isLevelUp={!groupRoundName ? true : false} 
                        dataRound={dataRound} groupRoundName={!groupRoundName ? "groupRound" + (index +1) : groupRoundName} 
                        isShowTrivia={dataRound.flow !== 19} onClickTrivia={this.handleClickTrivia} onClose={this.handleCloseKnowledge} 
                    />
                    })
                }
                {/* {[1, 2].map((item) => { */}
                <ErrorModal 
                    key={'err-modal'} 
                    kindError={kindError}
                    isShow={isShowErrConnectModal || isShowErr1Times1HourModal || isShowErr3Times1DayModal} 
                    onClose={isShowErrConnectModal ? this.handleCloseErrConnectModal : this.handleCloseErrModal} 
                />
                {/* })} */}
            </React.Fragment>
        )   
    }
    
    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { dataRound } = this.state;
        return (
            <React.Fragment>
                <div className="container-home">
                    {this.renderLayoutByFlow()}
                    {this.renderPopup()}
                </div>
                {
                    dataRound.flow === 17 || dataRound.flow === 18 ?
                    <div style={{visibility: (dataRound.flow === 18) ? 'visible' : 'hidden'}} className="container-30th-earth">
                        <video
                            id="video30thEarth"
                            className="video-30thearth" 
                            ref={(domVideo) => { this.domVideo = domVideo }}
                            muted
                            playsInline
                        >
                            <source src={VideoEarth} type="video/mp4"/>
                        </video>
                     </div>
                    : null
                }
               <div className="container-audio" 
               style={{visibility:'hidden'}} >
                    <video
                        ref={(domAudio) => { this.domAudio = domAudio }}
                        loop
                        playsInline
                        style={{ visibility: "hidden" }}
                        muted
                    >
                        <source src={AudioAlon} type="audio/mpeg"></source>
                    </video>
                    <audio
                        ref={(domBtnSoundSfx) => { this.domBtnSoundSfx = domBtnSoundSfx }}
                        style={{visibility:'hidden'}}
                        muted
                        id="btnSoundSfx"
                        src={BtnSoundSfx}
                        controls
                    />
                    <audio
                        ref={(domBtnPrimarySfx) => { this.domBtnPrimarySfx = domBtnPrimarySfx }}
                        style={{visibility:'hidden'}}
                        muted
                        id="btnPrimarySfx"
                        src={BtnPrimarySfx}
                        controls
                    />
                    <audio />
                    <audio 
                        ref={(domAudioNoti) => {this.domAudioNoti = domAudioNoti}}
                        style={{visibility:'hidden'}}
                        muted
                        src={AudioNiSoundNoti}
                        controls
                    />
                    <audio
                        ref={(domAudioNiSoundNextFlow) => {this.domAudioNiSoundNextFlow = domAudioNiSoundNextFlow}}
                        style={{visibility:'hidden'}}
                        muted
                        src={AudioNiSoundNextFlow}
                        controls
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default masterLayout(Home);