import React from 'react';
import ImgStampsBg1 from '../../../assets/images/day_eleven_to_fifteen/stamps_bg_1.svg';
import ImgStampsBg2 from '../../../assets/images/day_eleven_to_fifteen/stamps_bg_2.svg';
import ImgStampsBg3 from '../../../assets/images/day_eleven_to_fifteen/stamps_bg_3.svg';
import ImgStampsBg4 from '../../../assets/images/day_eleven_to_fifteen/stamps_bg_4.svg';
import ImgStampsBg5 from '../../../assets/images/day_eleven_to_fifteen/stamps_bg_5.svg';
import ImgStampsItem1 from '../../../assets/icons/day_eleven_to_fifteen/stamps_item_1.svg';  
import ImgStampsItem2 from '../../../assets/icons/day_eleven_to_fifteen/stamps_item_2.svg';  
import ImgStampsItem3 from '../../../assets/icons/day_eleven_to_fifteen/stamps_item_3.svg';  
import ImgStampsItem4 from '../../../assets/icons/day_eleven_to_fifteen/stamps_item_4.svg';        
import ImgStampsItem5 from '../../../assets/icons/day_eleven_to_fifteen/stamps_item_5.svg';  
import ImgStampsDay1Cloud1 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_1_1.svg';
import ImgStampsDay1Cloud2 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_1_2.svg';
import ImgStampsDay1Cloud3 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_1_3.svg';
import ImgStampsDay2Cloud1 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_2_1.svg';
import ImgStampsDay2Cloud2 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_2_2.svg';
import ImgStampsDay2Cloud3 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_2_3.svg';
import ImgStampsDay3Cloud1 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_3_1.svg';
import ImgStampsDay3Cloud2 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_3_2.svg';
import ImgStampsDay3Cloud3 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_3_3.svg';
import ImgStampsDay4Cloud1 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_4_1.svg';
import ImgStampsDay4Cloud2 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_4_2.svg';
import ImgStampsDay4Cloud3 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_4_3.svg';
import ImgStampsDay5Cloud1 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_5_1.svg';
import ImgStampsDay5Cloud2 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_5_2.svg';
import ImgStampsDay5Cloud3 from '../../../assets/images/day_eleven_to_fifteen/stamps_cloud_day_5_3.svg';
import './DayElevenToFifteen.scss';
/**
 * Class DayElevenToFifteen
 */
class DayElevenToFifteen extends React.Component {
    /**
     * Render background and image stamps
     * @return {object}
     */
    renderBackground = () => {
        const { indexStamps } = this.props;
        switch (indexStamps) {
            case 1:
                return {background: ImgStampsBg1, cloud1: ImgStampsDay1Cloud1, cloud2: ImgStampsDay1Cloud2, cloud3: ImgStampsDay1Cloud3, item: ImgStampsItem1};
            case 2:
                return {background: ImgStampsBg2, cloud1: ImgStampsDay2Cloud1, cloud2: ImgStampsDay2Cloud2, cloud3: ImgStampsDay2Cloud3, item: ImgStampsItem2};
            case 3:
                return {background: ImgStampsBg3, cloud1: ImgStampsDay3Cloud1, cloud2: ImgStampsDay3Cloud2, cloud3: ImgStampsDay3Cloud3, item: ImgStampsItem3};
            case 4:
                return {background: ImgStampsBg4, cloud1: ImgStampsDay4Cloud1, cloud2: ImgStampsDay4Cloud2, cloud3: ImgStampsDay4Cloud3, item: ImgStampsItem4}
            default:
                return {background: ImgStampsBg5, cloud1: ImgStampsDay5Cloud1, cloud2: ImgStampsDay5Cloud2, cloud3: ImgStampsDay5Cloud3, item: ImgStampsItem5};
        }
    }
    
    /**
     * Lifecycle    
     * Render jsx to browser
     * @return {jsx}
     */
    render() {
        const { background, cloud1, cloud2, cloud3, item } = this.renderBackground();
        const { indexStamps } = this.props;
        return (
            <React.Fragment>
                <div
                    className="app-container-stamps container-day-eleven-fifteen"
                    style={{
                        backgroundImage: `url(${background})`
                    }}
                >
                    <div className="img-cloud-1" style={{backgroundImage: `url(${cloud1})`}} ></div>
                    <div className="img-cloud-2" style={{backgroundImage: `url(${cloud2})`}} ></div>
                    <div className="img-cloud-3" style={{backgroundImage: `url(${cloud3})`}} ></div>
                </div>
                <div className="app-container-item-stamps-tap container-item-stamps-tap">
                    <img
                        alt=""
                        src={item}
                        draggable={false}
                        className={`app-img-item-stamps img-item-stamps-eleven-fifteen-${indexStamps}`}
                    />
                 </div>
            </React.Fragment>
        )
    }
}

export default DayElevenToFifteen;